import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TitleComp from '../../component/TitleComp';
import Select from 'react-select';
import { mastergetapi, masterpostapi, masterputapi } from '../../Api/Api';
import Loader from '../../component/Loader';
import { toast } from 'react-toastify';

function AddDocumentName() {
    const [name, setName] = useState("");
    const [usertype, setUserType] = useState([]);
    const [requiredin, setrequiredin] = useState([]);
    const [documenttype, setdocumenttype] = useState([]);
    const [fields, setFields] = useState([{ name: '', type: '' }]); // Initialize with an empty field object

    const [documenttypedata, setdocumenttypedata] = useState([]);
    const [loading, setloading] = useState("")

    const { state } = useLocation();


    const handleedit = () => {

        setName(state.name || "");

        const selectedDocumentType = state.document_type ? state.document_type.map((cat) => ({
            value: cat._id,
            label: cat.name,
        })) : [];

        setdocumenttype(selectedDocumentType);

        const selectedRequiredIn = state.required_in ? state.required_in.map((cat) => ({
            value: cat._id,
            label: cat.name,
        })) : [];
        setrequiredin(selectedRequiredIn);

        const selectedUserType = state.used_in ? state.used_in.map((cat) => ({
            value: cat._id,
            label: cat.name,
        })) : [];
        setUserType(selectedUserType);

        const fieldsData = state.fields || [{ name: '', type: '' }];
        setFields(fieldsData);

    };


    useEffect(() => {
        if (state) {
            handleedit()
        }

    }, [state])

    const [appcat, setAppCat] = useState([]);

    let token = localStorage.getItem("token");

    const handleSubmit = async (e) => {
        e.preventDefault();
        const requestdata = {
            name,
            document_type: documenttype.map(doc => doc.value), // Extracting only the values
            required_in: requiredin.map(doc => doc.value),
            used_in: usertype.map(doc => doc.value),
            fields: fields.map(field => ({ name: field.name, type: field.type })) // Extracting only the fields with name and type
        };




        let res = ""

        if (state) {
            requestdata._id = state._id
            res = await masterputapi('document_names', requestdata, token)

        } else {
            res = await masterpostapi('document_names', requestdata, token)
        }

        if (!res.error) {

            setloading(false)
            toast.success("Data Submit Succesfully")
        } else {
            setloading(false)
        }
    };

    const handleAppCategory = async () => {
        try {
            let res = await mastergetapi('app_category', token);

            setAppCat(res.data);
        } catch (error) {
            console.error("Error fetching app categories:", error);
        }
    };

    const handleDocumenttype = async () => {
        try {
            let res = await mastergetapi('document_types', token);

            setdocumenttypedata(res.data);
        } catch (error) {
            console.error("Error fetching app categories:", error);
        }
    };

    const options = appcat
        .filter(item => item.parent)  // Only include items with a parentId
        .map(item => ({
            value: item._id,
            label: item.name
        }));

    const documenttypeoptions = documenttypedata.map((item) => ({
        value: item._id,
        label: item.name
    }));

    useEffect(() => {
        handleAppCategory();
        handleDocumenttype();
    }, []);

    const handleAddRow = () => {
        setFields([...fields, { name: '', type: '' }]);
    };

    const handleRemoveRow = (index) => {
        const updatedFields = fields.filter((_, i) => i !== index);
        setFields(updatedFields);
    };

    const handleFieldChange = (index, event) => {
        const updatedFields = fields.map((field, i) =>
            i === index ? { ...field, [event.target.name]: event.target.value } : field
        );
        setFields(updatedFields);
    };

    const handledocument = (selectedValues) => {
        setdocumenttype(selectedValues);
    };
    const handleuser = (selectedValues) => {
        setUserType(selectedValues);
    };

    const handlerequiredin = (selectedValues) => {
        setrequiredin(selectedValues);
    };
    return (
        <>
            {loading && <Loader />}
            <section>
                <div className="container">
                    <div className="grid grid-cols-1">
                        <div className="cols-span-1">
                            <TitleComp title="Add Document Name" />
                        </div>
                        <div className="">
                            <div className="bg-white p-6 rounded-lg shadow-lg w-full">
                                <form className="" onSubmit={handleSubmit}>
                                    <div className="grid grid-cols-3 gap-4 align-middle">
                                        <div className="cols-span-1">
                                            <label htmlFor="name" className="block text-gray-700 text-sm font-medium mb-2">Name</label>
                                            <input
                                                type="text"
                                                id="name"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                placeholder="Enter Name"
                                            />
                                        </div>
                                        <div className="col-span-1">
                                            <label htmlFor="type" className="block text-gray-700 text-sm font-medium mb-2">Document Entity</label>

                                            <Select
                                                options={documenttypeoptions}
                                                isMulti
                                                value={documenttype}
                                                onChange={handledocument}
                                            />
                                        </div>
                                        <div className="col-span-1">
                                            <label htmlFor="type" className="block text-gray-700 text-sm font-medium mb-2">Used In</label>

                                            <Select
                                                options={options}
                                                isMulti
                                                value={usertype}
                                                onChange={handleuser}
                                            />
                                        </div>
                                        <div className="col-span-1">
                                            <label htmlFor="type" className="block text-gray-700 text-sm font-medium mb-2">Required In</label>

                                            <Select
                                                options={options}
                                                isMulti
                                                value={requiredin}
                                                onChange={handlerequiredin}
                                            />
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-3 gap-4 align-middle">
                                        <div className="col-span-3">
                                            <h3 className='maintitle mt-6 text-xl font-semibold headlandfont'>Fields</h3>
                                        </div>
                                        {fields.map((field, index) => (
                                            <React.Fragment key={index}>
                                                <div className="col-span-1">
                                                    <label htmlFor={`name-${index}`}>Name</label>
                                                    <input
                                                        type="text"
                                                        id={`name-${index}`}
                                                        name="name"
                                                        value={field.name}
                                                        onChange={(e) => handleFieldChange(index, e)}
                                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                        placeholder="Enter Name"
                                                    />
                                                </div>
                                                <div className="col-span-1">
                                                    <label htmlFor={`type-${index}`}>Type</label>
                                                    {/* <input
                                                        type="text"
                                                        id={`type-${index}`}
                                                        name="type"
                                                        value={field.type}
                                                        onChange={(e) => handleFieldChange(index, e)}
                                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                        placeholder="Enter Type"
                                                    /> */}
                                                    <select id={`type-${index}`}
                                                        name="type"
                                                        onChange={(e) => handleFieldChange(index, e)}
                                                        value={field.type} className=" block w-full bg-gray-200 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                                                        <option value="Text">Text</option>
                                                        <option value="File">File</option>
                                                        <option value="Date">Date</option>


                                                    </select>
                                                </div>
                                                <div className="col-span-1 flex items-center mt-7">
                                                    <button
                                                        type="button"
                                                        onClick={handleAddRow}
                                                        className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-5 rounded focus:outline-none focus:ring-2 focus:ring-blue-400 mr-2"
                                                    >
                                                        Add Row
                                                    </button>
                                                    <button
                                                        type="button"
                                                        onClick={() => handleRemoveRow(index)}
                                                        className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-5 rounded focus:outline-none focus:ring-2 focus:ring-red-400"
                                                    >
                                                        Remove Row
                                                    </button>
                                                </div>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                    <div className="cols-span-1">
                                        <button
                                            type="submit"
                                            className="mt-7 bg-blue-500 w-50 hover:bg-blue-600 text-white font-semibold py-2 px-5 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                                        >
                                            {state ? "Update" : "Submit"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AddDocumentName;
