import React, { useEffect, useState } from 'react'
import { superadmingetapi } from '../../Api/Api'
import TitleComp from '../../component/TitleComp'
import TableComp from '../../component/TableComp'
import { useNavigate } from 'react-router-dom'
import approve from "../../assets/images/approved.png"
import reject from "../../assets/images/rejected.png"
import Loader from '../../component/Loader'

function StoresList() {
    const [loading, setloading] = useState(false)
    let token = localStorage.getItem("token")
    const [data, setdata] = useState([])
    const [filterdata, setfilterdata] = useState([])
    const [verify, setverify] = useState("")
    const handlestore = async () => {
        setloading(true)
        let res = await superadmingetapi('fetch_store?all=1', token)
        console.log(res)
        if (!res.error) {
            setloading(false)
            setdata(res.data)
            setfilterdata(res.data)
        }
    }

    const navigate = useNavigate()


    const columns = [
        {
            field: "verified",
            headerName: "Verified Store",
            flex: 1,
            renderCell: (params) => {

                return (
                    <>
                        <img src={params.row?.verified ? approve : reject} className='h-[40px] w-[40px] bg-white shadow mt-1' alt="" />
                    </>
                )
            }
        },

        {
            field: "name",
            headerName: "Name",
            flex: 2,
        },
        {
            field: "Vendor",
            headerName: "Vendor",
            flex: 2,
            renderCell: (params) => {
                return params.row.user.phone
            }
        },
        {
            field: "radius",
            headerName: "Radius",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <span>{params.row.radius} Km</span>
                    </>
                )
            }
        },
        {
            field: "ratings",
            headerName: "Rating",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <span className='text-sm font-bold px-3 py-2 rounded-md bg-white shadow'>{params.row.ratings} <i class="fa-solid fa-star text-yellow-300"></i></span>

                    </>
                )
            }
        },

        {
            field: "time",
            headerName: "Store Time",
            flex: 2,
            renderCell: (params) => {
                return (
                    <>
                        <span className='bg-green-300 px-4 py-2 rounded-md text-white me-2'>{params.row.start_time}</span>
                        <span>-</span>
                        <span className='bg-red-400 px-4 py-2 rounded-md text-white ms-2'>{params.row.close_time}</span>
                    </>
                )
            }
        },

        {
            field: "Info",
            headerName: "Info",
            flex: 1,
            renderCell: (params) => {
                return <button className='cutombtn' onClick={(e) => navigate(`/stores/storeinfo/${params.row.user._id}`)}><i className="fa-solid fa-eye"></i></button>;
            },
        },

        {
            field: "Map",
            headerName: "Map",
            flex: 1,
            renderCell: (params) => {
                return (
                    (params.row.latitude && params.row.longitude) ? (
                        <button
                            className='cutombtn'
                            onClick={() => window.open(`http://maps.google.com/maps?q=${params.row.latitude},${params.row.longitude}`, '_blank')}
                        >
                            <i className="fa-solid fa-location-dot"></i>
                        </button>
                    ) : null
                );
            },
        },





    ];

    useEffect(() => {
        handlestore()
    }, [])

    const getRowId = (row) => row._id

    const handleVerify = () => {
        const verifiedStatus = verify ? JSON.parse(verify) : null;
        const res = data.filter((item) => item.verified == verifiedStatus);

        setfilterdata(res)

    };



    useEffect(() => {
        if (verify) {
            handleVerify()
        }
    }, [verify])



    const handleclear = (e) => {
        e.preventDefault()
        setverify("")
        setfilterdata(data)
    }
    return (
        <>
            {loading && <Loader />}
            <section>
                <div className="grid grid-cols-2 gap-4">
                    <div className="col-span-2">
                        <TitleComp title="Stores List" />
                    </div>
                    <div className="col-span-2">
                        <div className="grid grid-cols-5 gap-4 ">
                            <div className="col-span-1">
                                <select value={verify} onChange={(e) => setverify(e.target.value)} name="type" id="type" className="block w-full mb-3 bg-gray-200 border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  >
                                    <option value="" className='hidden' selected>Select Verified Store</option>
                                    <option value="true">Verify Store</option>
                                    <option value="false">Unverify Store</option>
                                </select>
                            </div>
                            <div className="col-span-2">
                                <button className='bg-blue-500 mt-1 text-white px-3 py-2 rounded-md' onClick={(e) => handleclear(e)}>Clear Filter</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-2">
                        <TableComp data={filterdata ?? data} columns={columns} getRowId={getRowId} />
                    </div>
                </div>
            </section>
        </>
    )
}

export default StoresList
