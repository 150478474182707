/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { mastergetapi, masterpostapi } from '../../Api/Api';
import TitleComp from '../../component/TitleComp';
import Loader from '../../component/Loader';
import Select from 'react-select';
import TableComp from '../../component/TableComp';

import convertDateToMonthName from '../../component/FormDate';

import storexl from "../../assets/images/store_master_product.xlsx"
import { toast } from 'react-toastify';
import vegimg from "../../assets/images/veg.png"
import nonveg from "../../assets/images/nonveg.jpg"
import approved from "../../assets/images/approved.png"
import rejected from "../../assets/images/rejected.png"
import { hasPermission } from '../../Access/Permission';

function MasterProduct() {
    let token = localStorage.getItem("token");
    const [data, setdata] = useState([]);
    const [cat, setcat] = useState("")
    const [name, setname] = useState("")

    const [productcat, setproductcat] = useState("")
    const [productcatdata, setproductcatdata] = useState([])
    const [editvarshow, seteditvarshow] = useState(false)
    const [editvariant, seteditvariant] = useState([])

    const [veg, setveg] = useState("")
    const [variantname, setvariantname] = useState("")
    const [mrp, setmrp] = useState("")
    const [returnable, setreturnable] = useState("")

    const [sellingprice, setsellingprice] = useState("")
    const [stock, setstock] = useState("")
    const [unitweight, setunitweight] = useState("")
    const [desc, setdesc] = useState("")
    const [hsnnumber, sethsnnumber] = useState("")







    const [catdata, setcatdata] = useState([])
    const [unit, setunit] = useState("")
    const [unitdata, setunitdata] = useState([])
    const [formshow, setformshow] = useState(false)
    const [branddata, setbranddata] = useState([])
    const [excel, setexcel] = useState(null)
    const [loading, setloading] = useState(false);
    const [uploadpopup, setuploadpopup] = useState(false)
    const [showPopup, setShowPopup] = useState(false);
    const [popupData, setPopupData] = useState(null); // Data for popup
    const [popupLoading, setPopupLoading] = useState(false); // Popup loading state

    // Fetching product list
    const handleget = async () => {
        let res = await mastergetapi('fetch_master_products', token);
        setdata(res.data);
    };

    const brandget = async () => {
        let res = await mastergetapi('brands')

        setbranddata(res.data)
    }
    const unitget = async () => {
        let res = await mastergetapi('units')
        setunitdata(res.data)
    }

    useEffect(() => {
        handleget();
        brandget();
        handlecategory();
        unitget();
    }, []);






    const handleproductvar = async (productId) => {
        setPopupLoading(true);
        setShowPopup(true);

        let res = await mastergetapi(`fetch_master_product_variants?_id=${productId}`, token);

        if (!res.error) {
            setPopupData(res.data);
        }
        setPopupLoading(false);
    };

    const getRowId = (row) => row._id;

    const handleconnected = async () => {
        let res = await mastergetapi(`product_category?connected_app_category=${cat.value}`, token)

        setproductcatdata(res.data)


    }

    useEffect(() => {
        if (cat) {
            handleconnected()
        }
    }, [cat])

    const categoryColors = {
        Restaurants: 'bg-red-500',
        Grocery: 'bg-green-500',
        Electronics: 'bg-blue-500',
        Fashion: 'bg-pink-500',
        Pharma: 'bg-purple-500',
        Hardware: 'bg-yellow-500',
    };

    const handlecategory = async () => {
        setloading(true)
        let res = await mastergetapi(`app_category?parent=668f77707a7b71ee72e8c4d4`, token)




        if (!res.error) {
            setcatdata(res.data)
            setloading(false)
        } else {
            setloading(false)
        }

    }

    const columns = [
        {
            field: 'name',
            flex: 1,
            headerName: 'Product Name',
            renderCell: (row) => row.name,
        },
        {
            field: 'brands',
            flex: 1,
            headerName: 'Brands',
            renderCell: (params) => (params.row?.brands?.name),
        },
        {
            field: 'appcategory',
            flex: 1,
            headerName: 'App Category',
            renderCell: (params) => {
                const appCategoryName = params.row?.connected_app_category?.name || 'N/A';
                const badgeColor = categoryColors[appCategoryName] || 'bg-gray-500'; // Default to gray if category not found
                return (
                    <span className={`${badgeColor} text-white px-3 py-1 rounded-full text-xs font-semibold`}>
                        {appCategoryName}
                    </span>
                );
            },
        },
        {
            field: 'is_returnable',
            flex: 1,
            headerName: 'Returnable',
            renderCell: (params) => (params.row.is_returnable ? <img src={approved} className='h-[30px] mt-2' alt="" /> : <img src={rejected} className='h-[30px] mt-2' alt="" />),
        },
        {
            field: 'veg',
            flex: 1,
            headerName: 'Veg/Non-Veg',
            renderCell: (params) => (params.row.veg ? <img src={vegimg} className='h-[30px] mt-2' alt="" /> : <img src={nonveg} className='h-[30px] mt-2' alt="" />),
        },

        {
            field: "insdffsdfo",
            headerName: "Product Variants",
            flex: 1,
            renderCell: (params) => { // Corrected renderCell casing
                return <button className='cutombtn' onClick={() => handleproductvar(params.row._id)}><i className="fa-solid fa-circle-info"></i></button>;
            },
        },
    ];

    const handleecxelsubmit = async (e) => {
        e.preventDefault()
        setloading(true)
        let formdata = new FormData();
        formdata.append("excel_file", excel)
        let res = await masterpostapi('upload_bulk_master', formdata, token)


        if (res.status == "OK") {
            setloading(false)
            toast.success("Excel File Uploaded Successfully")
            setuploadpopup(false)
            handleget()
        } else {
            setuploadpopup(false)
            setloading(false)
            toast.error("Excel File Not Uploaded Successfully")

        }


    }


    const handlefile = (e) => {
        e.preventDefault()
        const file = e.target.files[0];

        setexcel(file)
    }

    const catoption = catdata.map((item) => ({
        value: item._id,
        label: item.name
    }));

    const handleselect = (selectedvalue) => {
        setcat(selectedvalue)
        setproductcat("")
    }

    const [brand, setBrand] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [unitvalue, setunitvalue] = useState('')
    const [productcatvalue, setproductcatvalue] = useState('')


    const optionproductcat = productcatdata.map((item) => ({
        value: item.name,
        label: item.name
    }));

    const getoptioncat = () => {
        if (productcatvalue && !optionproductcat.some(option => option.label.toLowerCase() === productcatvalue.toLowerCase())) {
            return [...optionproductcat, { value: productcatvalue, label: `${productcatvalue}` }];
        }
        return optionproductcat;
    };



    const handleoptioncat = (selectedValue) => {
        setproductcat(selectedValue);
    };


    const optionunit = unitdata.map((item) => ({
        value: item.name,
        label: item.name
    }));

    const unitoption = () => {
        if (unitvalue && !optionunit.some(option => option.label.toLowerCase() === unitvalue.toLowerCase())) {
            return [...optionunit, { value: unitvalue, label: `${unitvalue}` }];
        }
        return optionunit;
    };



    const handleunitselect = (selectedValue) => {
        setunit(selectedValue);
    };

    // Convert the brand data into the appropriate format for react-select
    const brandOptions = branddata.map((item) => ({
        value: item.name,
        label: item.name
    }));


    const handleclear = () => {
        setvariantname("")
        setmrp("")
        setveg("")
        setunit("")
        setreturnable("")
        setproductcat("")
        setcat("")
        setsellingprice("")
        setunitweight("")
        setstock("")
        setname("")
        setBrand("")
        sethsnnumber("")
    }


    // Handle the selection of an option (either an existing brand or a new one)
    const handleSelect = (selectedValue) => {
        setBrand(selectedValue);
    };

    // Add a dynamic option "Add new brand" if the input value is not in the list
    const getOptions = () => {
        if (inputValue && !brandOptions.some(option => option.label.toLowerCase() === inputValue.toLowerCase())) {
            return [...brandOptions, { value: inputValue, label: `${inputValue}` }];
        }
        return brandOptions;
    };


    const handlesingleproduct = async (e) => {
        e.preventDefault();
        setloading(true)

        // Convert product_category array to comma-separated string
        const formattedProductCategory = productcat.map(item => item.value).join(',');

        let requestdata = {
            name: name,
            category: cat.label,
            product_category: formattedProductCategory, // Use the formatted string
            brands: brand?.value,
            description: desc,
            veg: veg,
            is_returnable: returnable,
            variant_name: variantname,
            mrp: mrp,
            selling_price: sellingprice,
            unit_weight: unitweight,
            units: unit?.value,
            stock: stock,
            hsn_number: hsnnumber
        };

        let res = await masterpostapi(`upload_single_master`, requestdata, token);

        if (res.status == "OK") {
            setloading(false)
            handleget()
            toast.success("Data Uploaded Successfully")
            setuploadpopup(false)
            setformshow(false)
            handleclear()
        } else {
            setuploadpopup(false)
            setloading(false)
            toast.error("Data Not Uploaded Successfully")
            setformshow(false)

        }
    };


    const handleeditvariant = (e, item) => {
        e.preventDefault()
        seteditvariant(item)
        setShowPopup(false)
        seteditvarshow(true)
        setunit({
            value: item.units._id,
            label: item.units.name,
        });

        setvariantname(item.name)
        setmrp(item.mrp)

        setsellingprice(item.selling_price)
        setunitweight(item.unit_weight)
        setstock(item.stock)
        sethsnnumber(item.hsn_number)


    }





    return (
        <>
            {loading && <Loader />}
            <section>
                <div className="container">
                    <div className="grid grid-cols-3 gap-10">
                        <div className="col-span-3">
                            <TitleComp title="Master Products" />
                        </div>
                        {hasPermission('Products', 'Write') && <div className="col-span-3">
                            <div className="text-end mb-5 ">
                                <a
                                    href={storexl}
                                    download
                                    className="btn bg-green-500 px-5 py-3 rounded-md text-white"
                                >
                                    Download Excel Format
                                </a>
                                <a
                                    onClick={(e) => setformshow(true)}
                                    className="btn bg-blue-500 px-5 py-3 rounded-md text-white ms-3 cursor-pointer"
                                >
                                    Upload Single Product
                                </a>

                                <a
                                    href="#"
                                    onClick={(e) => setuploadpopup(true)}
                                    className="btn bg-blue-500 ms-3 px-5 py-3 rounded-md text-white"
                                >
                                    Upload Excel Format
                                </a>


                            </div>

                        </div>}

                    </div>
                    <div className="grid grid-cols-1 gap-6">
                        <div className="col-span-1">
                            <TableComp data={data} columns={columns} getRowId={getRowId} />
                        </div>
                    </div>
                </div>
            </section>


            {uploadpopup && (
                <>
                    <div className="fixed  inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75" style={{ zIndex: "999" }} >
                        <div className="bg-white relative rounded-lg shadow-lg md:w-[30%] w-full">
                            <h2 className="flex justify-between items-center text-xl font-bold  bg-gray-200 p-3 rounded-t-lg">
                                Upload Your Products
                            </h2>

                            <div className="p-6">
                                {/* <p className="text-gray-600 mb-4">
                                    Please upload your product details using the provided Excel format. Make sure the file is correctly formatted before submission.
                                </p> */}

                                <form onSubmit={handleecxelsubmit}>
                                    <div className="grid grid-cols-1 gap-4">
                                        <div className="col-span-1">
                                            <label className="block mb-2 text-sm text-gray-700">
                                                Select Excel File:
                                            </label>
                                            <input
                                                type="file"
                                                onChange={(e) => handlefile(e)}
                                                className="bg-white outline-none border border-gray-300 px-4 py-2 rounded-md w-full"
                                                accept=".xlsx, .xls"
                                            />
                                        </div>
                                        <div className="col-span-2">
                                            <button className='btn bg-blue-500 text-white px-4 py-3 rounded-lg'>Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <button
                                className="btn bg-blue-500 text-white px-4 py-2 mt-4 absolute top-[-32px] right-[-18px] rounded-full"
                                onClick={() => setuploadpopup(false)}
                            >
                                <i className="fa-solid fa-xmark"></i>
                            </button>
                        </div>

                    </div>
                </>
            )}

            {formshow && (
                <>
                    <div className="fixed  inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75" style={{ zIndex: "999" }} >
                        <div className="bg-white relative rounded-lg shadow-lg lg:w-[50%] w-full ">
                            <h2 className="flex justify-between items-center text-xl font-bold  bg-gray-200 p-3 rounded-t-lg">
                                Upload Single Product
                            </h2>

                            <div className="p-6 overflow-x-auto" style={{ height: "80vh" }}>


                                <form onSubmit={handlesingleproduct}>
                                    <div className="grid grid-cols-4 gap-4 items-center">
                                        <div className="col-span-2">
                                            <label htmlFor="name" className="block text-gray-700 font-medium mb-2">Name</label>
                                            <input
                                                type="text"
                                                value={name}
                                                onChange={(e) => setname(e.target.value)}
                                                className="border border-gray-300 rounded-md px-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            />

                                        </div>

                                        <div className="col-span-2">
                                            <label htmlFor="name" className="block text-gray-700 font-medium mb-2">Brands</label>


                                            <Select
                                                options={getOptions()}
                                                value={brand}

                                                onChange={handleSelect}
                                                onInputChange={(value) => setInputValue(value)}
                                                placeholder="Select or add a brand"
                                            />

                                        </div>
                                        <div className="col-span-2">
                                            <label htmlFor="">Category</label>
                                            <Select
                                                options={catoption}
                                                value={cat}
                                                onChange={handleselect}
                                            />
                                        </div>
                                        <div className="col-span-2">
                                            <label htmlFor="name" className="block text-gray-700 font-medium mb-2">Product Category</label>


                                            <Select
                                                options={getoptioncat()}
                                                value={productcat}
                                                isMulti
                                                onChange={handleoptioncat}
                                                onInputChange={(value) => setproductcatvalue(value)}
                                                placeholder="Select or add a Product Category"
                                            />

                                        </div>
                                        <div className="col-span-1">
                                            <label htmlFor="veg" className="block text-gray-700 font-medium mb-2">Veg</label>
                                            <select
                                                id="veg"
                                                name="veg"
                                                value={veg}
                                                onChange={(e) => setveg(e.target.value)}
                                                className="block w-full border border-gray-300 rounded-md px-4 py-2 bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                            >
                                                <option value="">Select option</option>
                                                <option value="TRUE">Veg</option>
                                                <option value="FALSE">Non-Veg</option>
                                            </select>
                                        </div>
                                        <div className="col-span-1">
                                            <label htmlFor="veg" className="block text-gray-700 font-medium mb-2">Returnable</label>
                                            <select
                                                id="veg"
                                                name="veg"
                                                value={returnable}
                                                onChange={(e) => setreturnable(e.target.value)}
                                                className="block w-full border border-gray-300 rounded-md px-4 py-2 bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                            >
                                                <option value="">Select option</option>
                                                <option value="TRUE">Yes</option>
                                                <option value="FALSE">No</option>
                                            </select>
                                        </div>

                                        <div className="col-span-1">
                                            <label htmlFor="name" className="block text-gray-700 font-medium mb-2">Variant Name</label>
                                            <input
                                                type="text"
                                                value={variantname}
                                                onChange={(e) => setvariantname(e.target.value)}
                                                className="border border-gray-300 rounded-md px-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            />

                                        </div>
                                        <div className="col-span-1">
                                            <label htmlFor="name" className="block text-gray-700 font-medium mb-2">MRP</label>
                                            <input
                                                type="text"
                                                value={mrp}
                                                onChange={(e) => setmrp(e.target.value)}
                                                className="border border-gray-300 rounded-md px-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            />

                                        </div>
                                        <div className="col-span-1">
                                            <label htmlFor="name" className="block text-gray-700 font-medium mb-2">Selling Price</label>
                                            <input
                                                type="text"
                                                value={sellingprice}
                                                onChange={(e) => setsellingprice(e.target.value)}
                                                className="border border-gray-300 rounded-md px-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            />

                                        </div>
                                        <div className="col-span-1">
                                            <label htmlFor="name" className="block text-gray-700 font-medium mb-2">Stock</label>
                                            <input
                                                type="text"
                                                value={stock}
                                                onChange={(e) => setstock(e.target.value)}
                                                className="border border-gray-300 rounded-md px-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            />

                                        </div>
                                        <div className="col-span-1">
                                            <label htmlFor="name" className="block text-gray-700 font-medium mb-2">Units</label>


                                            <Select
                                                options={unitoption()}
                                                value={unit}
                                                onChange={handleunitselect}
                                                onInputChange={(value) => setunitvalue(value)}
                                                placeholder="Select or add a Unit"
                                            />

                                        </div>
                                        <div className="col-span-1">
                                            <label htmlFor="name" className="block text-gray-700 font-medium mb-2">Unit Weight</label>
                                            <input
                                                type="text"
                                                value={unitweight}
                                                onChange={(e) => setunitweight(e.target.value)}
                                                className="border border-gray-300 rounded-md px-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            />

                                        </div>

                                        <div className="col-span-1">
                                            <label htmlFor="name" className="block text-gray-700 font-medium mb-2">HSN Number</label>
                                            <input
                                                type="text"
                                                value={hsnnumber}
                                                onChange={(e) => sethsnnumber(e.target.value)}
                                                className="border border-gray-300 rounded-md px-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            />

                                        </div>

                                        <div className="col-span-3">
                                            <label htmlFor="" className="block text-gray-700 font-medium mb-2">Description</label>
                                            <textarea name="" value={desc}
                                                onChange={(e) => setdesc(e.target.value)} className="border border-gray-300 rounded-md px-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500" id=""></textarea>
                                        </div>
                                        <div className="col-span-1">
                                            <button className='btn bg-blue-500 text-white px-4 py-3 rounded-lg'>Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <button
                                className="btn bg-blue-500 text-white px-4 py-2 mt-4 absolute top-[-32px] right-[-18px] rounded-full"
                                onClick={() => setformshow(false)}
                            >
                                <i className="fa-solid fa-xmark"></i>
                            </button>
                        </div>

                    </div>
                </>
            )}

            {showPopup && (
                <div className="fixed  inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75" style={{ zIndex: "999" }}>
                    <div className="bg-white relative p-6 rounded-lg shadow-lg w-[50%]">
                        <h2 className="text-2xl font-bold mb-4">Product Variants</h2>

                        {popupLoading ? (
                            <div className="flex justify-center items-center">
                                <Loader /> {/* Show loader while fetching data */}
                            </div>
                        ) : popupData && popupData.length > 0 ? (
                            <div className='overflow-y-auto py-10' style={{ height: popupData.length > 4 ? "500px" : "auto" }}>
                                <div className="grid md:grid-cols-2 grid-cols-1 gap-10">
                                    {popupData.map((item) => (
                                        <div className="col-span-1">
                                            <div className="card">
                                                <div className="image-container" style={{ height: "0px" }}>

                                                    <div className="price">
                                                        <del className="text-red-600">₹{item.mrp}</del>
                                                        <span className="text-emerald-600"> ₹{item.selling_price}</span>
                                                    </div>
                                                </div>



                                                <div className="content">
                                                    {/* <div className="brand flex justify-between">
                                                        <div>{item?.product?.name || 'Store Name Not Available'}</div>

                                                    </div> */}
                                                    <div className="product-name mb-1 flex justify-between">
                                                        <h3>
                                                            {item.product?.name || 'Product Name Not Available'}
                                                            <span className="text-emerald-400 text-lg ms-1">
                                                                {/* ({item.unit_weight} {item.units?.name !== "other" ? item.units.name : ''}) */}
                                                                ({item.name} )
                                                            </span>
                                                        </h3>

                                                    </div>

                                                    <div className="product-name">
                                                        <div className="flex justify-between">
                                                            <h6>
                                                                <span>HSN : {item.hsn_number || 'Not Available'}</span>
                                                            </h6>
                                                            <button className='btn bg-blue-400 text-white px-3 py-2 absolute right-[10px] top-[30px] rounded-lg' onClick={(e) => handleeditvariant(e, item)}><i className="fa-solid fa-pen-to-square"></i></button>
                                                        </div>
                                                        <h6> <span>Stock : {item.stock || 'Not Available'}</span></h6>
                                                        <h6 className="text-gray-600">Created At : {convertDateToMonthName(item.created_at)}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    ))}
                                </div>
                            </div>
                        ) : (
                            <p>No variants found for this product.</p>
                        )}

                        <button
                            className="btn bg-blue-500 text-white px-4 py-2 mt-4 absolute top-[-32px] right-[-18px] rounded-full"
                            onClick={() => setShowPopup(false)}
                        >
                            <i className="fa-solid fa-xmark"></i>
                        </button>
                    </div>
                </div>
            )}


            {editvarshow && (
                <div className="fixed  inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75" style={{ zIndex: "999" }}>
                    <div className="bg-white relative p-6 rounded-lg shadow-lg w-[50%]">
                        <h2 className="text-2xl font-bold mb-4">Edit Product Variants</h2>

                        {popupLoading ? (
                            <div className="flex justify-center items-center">
                                <Loader /> {/* Show loader while fetching data */}
                            </div>
                        ) : popupData && popupData.length > 0 ? (
                            <div className='overflow-y-auto py-10' style={{ height: popupData.length > 4 ? "500px" : "auto" }}>
                                <form action="">
                                    <div className="grid md:grid-cols-2 grid-cols-1 gap-4 px-2 items-center">

                                        <div className="col-span-1">
                                            <label htmlFor="">Variant Name</label>
                                            <input type="text" className="border border-gray-300 rounded-md px-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500" value={variantname} onChange={(e) => setvariantname(e.target.value)} />

                                        </div>
                                        <div className="col-span-1">
                                            <label htmlFor="">Mrp</label>
                                            <input type="text" className="border border-gray-300 rounded-md px-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500" value={mrp} onChange={(e) => setmrp(e.target.value)} />

                                        </div>
                                        <div className="col-span-1">
                                            <label htmlFor="">Selling Price</label>
                                            <input type="text" className="border border-gray-300 rounded-md px-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500" value={sellingprice} onChange={(e) => setsellingprice(e.target.value)} />

                                        </div>
                                        <div className="col-span-1">
                                            <label htmlFor="">Unit Weight</label>
                                            <input type="text" className="border border-gray-300 rounded-md px-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500" value={unitweight} onChange={(e) => setunitweight(e.target.value)} />

                                        </div>
                                        <div className="col-span-1">
                                            <label htmlFor="name" className="block text-gray-700 font-medium mb-2">Units</label>


                                            <Select
                                                options={unitoption()}
                                                value={unit}
                                                onChange={handleunitselect}
                                                onInputChange={(value) => setunitvalue(value)}
                                                placeholder="Select or add a Unit"
                                            />

                                        </div>
                                        <div className="col-span-1">
                                            <label htmlFor="">Stock</label>
                                            <input type="text" className="border border-gray-300 rounded-md px-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500" value={stock} onChange={(e) => setstock(e.target.value)} />

                                        </div>
                                        <div className="col-span-1">
                                            <label htmlFor="">HSN Number</label>
                                            <input type="text" className="border border-gray-300 rounded-md px-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500" value={hsnnumber} onChange={(e) => sethsnnumber(e.target.value)} />

                                        </div>
                                        <div className="col-span-2">
                                            <button className='btn bg-blue-500 px-4 py-3 rounded-lg text-white'>Submit</button>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        ) : (
                            <p>No variants found for this product.</p>
                        )}

                        <button
                            className="btn bg-blue-500 text-white px-4 py-2 mt-4 absolute top-[-32px] right-[-18px] rounded-full"
                            onClick={() => { seteditvarshow(false); handleclear() }}
                        >
                            <i className="fa-solid fa-xmark"></i>
                        </button>
                    </div>
                </div>
            )}


        </>
    );
}

export default MasterProduct;







