




import { useState, useEffect } from "react";
import { Link, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import '../assets/css/sidebar.css'

import shortlogo from "../assets/images/logo.png";
import logo from "../assets/images/logo.png";
import { superadmingetapi } from "../Api/Api";
import { menuData } from "../Data/Menudata";
import { hasPermission } from "../Access/Permission";


// eslint-disable-next-line react/prop-types
const Sidebar = ({ content }) => {
    // const status = localStorage.getItem("status");
    const [profiledata, setprofiledata] = useState([])
    let username = localStorage.getItem("username")
    const location = useLocation()

    const { id } = useParams()

    const userinfo = location.pathname == `/user/stores/${id}`

    const navigate = useNavigate()
    const [isCollapsed, setIsCollapsed] = useState(
        localStorage.getItem("isCollapsed") === "true"
    );

    // const navigate = useNavigate();

    const handleToggleSidebar = (e) => {
        e.preventDefault();
        setIsCollapsed(!isCollapsed);
        localStorage.setItem("isCollapsed", isCollapsed ? "false" : "true");
    };

    let token = localStorage.getItem("token")

    useEffect(() => {
        checkCollapse();
    }, []);

    const handleget = async () => {
        if (hasPermission('User', "Read")) {
            let res = await superadmingetapi(`details`, token)

            setprofiledata(res.data[0])
        }
    }

    useEffect(() => {
        handleget()
    }, [])

    const checkCollapse = () => {
        if (localStorage.getItem("isCollapsed") === "true") {
            setIsCollapsed(true);
        }
    };

    const handleLogout = () => {
        localStorage.clear();
    };








    const userRoles = JSON.parse(localStorage.getItem('roles'));




    const filteredMenuData = menuData.filter(menuItem => {
        const role = menuItem.type;
        return role === "all" || (userRoles[role] && userRoles[role].includes('Read')) || username == "superadmin";
    });



    const [openDropdown, setOpenDropdown] = useState('');

    const toggleDropdown = (e, itemName) => {
        e.preventDefault();
        setOpenDropdown(openDropdown === itemName ? "" : itemName);
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollToTop()
    }, [])



    const handleprofile = (e) => {
        e.preventDefault();


        navigate('/userinfo', { state: profiledata });


    };








    return (
        <div className="flex w-full">


            <ToastContainer />
            <div className={`sidebarContainer ${isCollapsed ? "collapsed" : ""}`}>
                <div className="w-full h-full relative">
                    <div className="sidebar-header flex justify-center">
                        <Link
                            className="btn text-dark bg-white"
                            id="closeSidebar"
                            onClick={(e) => handleToggleSidebar(e)}
                        >
                            {isCollapsed ? (
                                <i className="fa-solid fa-xmark"></i>
                            ) : (
                                <i className="fa-solid fa-bars"></i>
                            )}
                        </Link>
                        <Link className="hidden md:block">
                            {isCollapsed ? (
                                <img
                                    src={shortlogo}
                                    alt=""
                                    className="img-fluid mt-1"
                                    style={{ width: "130px" }}
                                />
                            ) : (
                                <img src={logo} style={{ width: "45px" }} alt="" className="img-fluid beat_logo mt-1" />
                            )}
                        </Link>
                    </div>
                    <div className="sidebar-content" id="Sidebar_nav">

                        <ul className="list-none">
                            {filteredMenuData.map((item, index) => (
                                <li key={index}>
                                    {item.subMenu ? (
                                        <div className={`${item.type}`}>
                                            <NavLink
                                                to={item.link}

                                                className={`flex items-center gap-2 Sidebar_link  `}
                                                onClick={(e) => toggleDropdown(e, item.name)}
                                            >
                                                <span className="icon">{item.icon}</span>
                                                <p className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}>
                                                    {item.name}

                                                </p>
                                            </NavLink>
                                            {openDropdown === item.name && (
                                                <ul className="list-none" style={{ paddingLeft: "20px" }}> {/* Adjust padding as needed */}
                                                    {item.subMenu.map((subItem, subIndex) => (
                                                        <li key={subIndex} className={`${item.type}`}>
                                                            <NavLink
                                                                to={subItem.link}
                                                                className="flex items-center gap-2 Sidebar_link"
                                                                onClick={(e) => item.isLogout && handleLogout(e)}
                                                            >
                                                                <span className="icon"><i className="fa-solid fa-circle-dot"></i></span>
                                                                <p className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}>
                                                                    {subItem.name}
                                                                </p>
                                                            </NavLink>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    ) : (
                                        <NavLink
                                            to={item.link}
                                            className={`flex items-center gap-2 Sidebar_link  `}
                                            onClick={(e) => item.isLogout && handleLogout(e)}
                                        >
                                            <span className={`icon ${item.isLogout ? "bg-dark text-white" : ""}`}>{item.icon}</span>
                                            <p className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`} >
                                                {item.name}

                                            </p>
                                        </NavLink>
                                    )}
                                </li>
                            ))}




                        </ul>
                    </div>
                    {/* <div className="sidebar-footer">

                        <div >
                            <div className="sidebar-content">
                                <ul>
                                    <li>
                                        <NavLink
                                            to={"/login"}
                                            className={`flex items-center gap-2 Sidebar_link `}
                                            onClick={(e) => handleLogout(e)}
                                        >
                                            <span className="icon"><i className="fa-solid fa-right-from-bracket"></i></span>
                                            <p className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}>
                                                Logout

                                            </p>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div> */}
                </div>
            </div>
            <div className={`mainContainer ${isCollapsed ? "collapsed" : ""}`}>
                <div className="w-full h-full e">
                    <div className="w-full sticky top-0 ">
                        <nav className="w-full topbarNav bg-nav bg-white  py-3 z-20 relative">
                            <div className="container mx-auto">
                                <div className="flex w-full justify-between items-center">
                                    <div className="px-5">
                                        <Link
                                            className="btn border-0 shadow-none makeExtend bg-white "
                                            onClick={handleToggleSidebar}
                                        >
                                            {isCollapsed ? (
                                                <i className="fa-solid fa-bars"></i>
                                            ) : (
                                                <i className="fa-solid fa-xmark"></i>
                                            )}
                                        </Link>
                                        <div className="mobilelogo">
                                            <Link className="block md:hidden">
                                                <img
                                                    src={logo}
                                                    className="img-fluid mobile_fullname_logo"
                                                    alt=""
                                                />
                                            </Link>
                                            <Link className="hidden md:block">
                                                <img
                                                    src={logo}
                                                    alt=""
                                                    className="img-fluid beat_logo"
                                                />
                                                <img
                                                    src={logo}
                                                    className="img-fluid fullname_logo"
                                                    alt=""
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                    <h3 className="text-end bg-blue-500 me-5 py-1 cursor-pointer rounded-md text-white px-5" onClick={(e) => handleprofile(e)}>{username}</h3>
                                </div>

                            </div>
                        </nav>
                        <div className={`container mx-auto ${userinfo ? "p-0" : "p-3"}`}>
                            <div className={`grid gap-3 ${userinfo ? "mt-0" : "mt-3"}`}>{content}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Sidebar;

