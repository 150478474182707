import axios from "axios";
import { BASE_URL } from "./BaseUrl";

const checktype = localStorage.getItem("username")

let urltype = checktype == "staff" ? "staff" : "superadmin"

export const superadminloginapi = async (endpoint, requestdata) => {
    try {
        const items = await axios.post(`${BASE_URL}superadmin/${endpoint}`, requestdata);
        return items.data;
    } catch (errors) {

        return { data: errors?.response?.data }
    }
}

export const staffloginapi = async (endpoint, requestdata) => {
    try {
        const items = await axios.post(`${BASE_URL}staff/${endpoint}`, requestdata);
        return items.data;
    } catch (errors) {

        return { data: errors?.response?.data }
    }
}

export const superadminpostapi = async (endpoint, requestdata, token) => {
    const headers = {
        Authorization: `Bearer ${token}`
    }
    try {
        const items = await axios.post(`${BASE_URL}${urltype}/${endpoint}`, requestdata, { headers });
        return items.data;
    } catch (errors) {

        return { data: errors?.response?.data }
    }
}

export const superadminputapi = async (endpoint, requestdata, token) => {
    const headers = {
        Authorization: `Bearer ${token}`
    }
    try {
        const items = await axios.put(`${BASE_URL}${urltype}/${endpoint}`, requestdata, { headers });
        return items.data;
    } catch (errors) {

        return { data: errors?.response?.data }
    }
}

export const superadmingetapi = async (endpoint, token) => {
    const headers = {
        Authorization: `Bearer ${token}`
    }
    try {
        const items = await axios.get(`${BASE_URL}${urltype}/${endpoint}`, { headers });
        return items.data;
    } catch (errors) {

        return { data: errors.response.data }
    }
}


export const mastergetapi = async (endpoint, token) => {
    const headers = {
        Authorization: `Bearer ${token}`
    }
    try {
        const items = await axios.get(`${BASE_URL}master/${endpoint}`, { headers });
        return items.data;
    } catch (errors) {

        return { data: errors?.response?.data }
    }
}

export const masterpostapi = async (endpoint, requestdata, token) => {
    const headers = {
        Authorization: `Bearer ${token}`
    }
    try {
        const items = await axios.post(`${BASE_URL}master/${endpoint}`, requestdata, { headers });
        return items.data;
    } catch (errors) {

        return { data: errors?.response?.data }
    }
}

export const masterputapi = async (endpoint, requestdata, token) => {
    const headers = {
        Authorization: `Bearer ${token}`
    }
    try {
        const items = await axios.put(`${BASE_URL}master/${endpoint}`, requestdata, { headers });
        return items.data;
    } catch (errors) {

        return { data: errors.response.data }
    }
}


export const masterdeleteapi = async (endpoint, requestdata, token) => {

    const headers = {
        Authorization: `Bearer ${token}`
    }
    try {
        const items = await axios.delete(`${BASE_URL}master/${endpoint}`, requestdata, { headers });
        return items.data;
    } catch (errors) {

        return { data: errors.response.data }
    }
}