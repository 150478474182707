import { Outlet, Navigate } from "react-router-dom";
import Sidebar from '../component/Sidebar';

function Layout() {
    const token = localStorage.getItem('token'); // Assuming token is stored in localStorage
    const roles = localStorage.getItem('roles'); // Assuming token is stored in localStorage

    console.log(roles)
    if (!token) {
        return <Navigate to="/login" replace />;
    }
    if (!roles) {
        return <Navigate to="/login" replace />;
    }

    return (
        <>
            <Sidebar content={<Outlet />} />
        </>
    );
}

export default Layout;
