import React, { useEffect, useState } from 'react';
import TitleComp from '../../component/TitleComp';
import { BASE_URL } from '../../Api/BaseUrl';
import axios from 'axios';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Loader from '../../component/Loader';
import { masterdeleteapi, mastergetapi, masterpostapi, masterputapi } from '../../Api/Api';
import TableComp from '../../component/TableComp';
import convertDateToMonthName from '../../component/FormDate';
import { toast } from 'react-toastify';

function Policy() {
    let token = localStorage.getItem("token");
    const [data, setdata] = useState([])
    const [loading, setloading] = useState(false)
    const [editid, seteditid] = useState("")

    const headers = {
        Authorization: `Bearer ${token}`
    };

    const [formData, setFormData] = useState({
        title: '',
        heading: '',
        description: '',
        type: 'Policy',
        url: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };



    const getRowId = (row) => row._id
    const columns = [
        {
            field: "title",
            headerName: "Title",
            flex: 1,
        },
        {
            field: "heading",
            headerName: "Heading",
            flex: 1,
        },
        {
            field: "type",
            headerName: "Type",
            flex: 1,
        },

        {
            field: "created_at",
            headerName: "Created Date",
            flex: 1,
            renderCell: (params) => { // Corrected renderCell casing
                return <span>{convertDateToMonthName(params.row.created_at)}</span>;
            },
        },

        {
            field: "edit",
            headerName: "Edit",
            flex: 1,
            renderCell: (params) => { // Corrected renderCell casing
                return <button className='cutombtn' onClick={(e) => handleEdit(e, params.row)} ><i className="fa-solid fa-pen-to-square"></i></button>;
            },
        },
        {
            field: "delete",
            headerName: "Delete",
            flex: 1,
            renderCell: (params) => { // Corrected renderCell casing
                return <button className='cutombtn' onClick={(e) => handledelete(e, params.row._id)}><i className="fa-solid fa-trash"></i></button>;
            },
        },

    ];


    const handleEdit = (e, item) => {
        e.preventDefault();
        seteditid(item._id)
        setFormData({
            title: item.title,
            heading: item.heading,
            description: item.description,
            type: item.type,
            url: item.title.toLowerCase().replace(/\s+/g, '-'),
        })
    };

    const handleget = async () => {
        let res = await mastergetapi('pages', token)

        if (!res.error) {
            setdata(res.data)
        }
    }

    useEffect(() => {
        handleget()
    }, [])

    const handledelete = async (e, id) => {
        const todayDate = new Date().toISOString();

        let requestdata = {
            data: {
                _id: id
            }
        }
        let res = await masterdeleteapi(`pages`, requestdata, token);

        if (!res.error) {
            toast.success("Page deleted successfully");
            handleget();
        } else {
            toast.error("Failed to delete Page. Please try again.");
        }
    };






    const handleSubmit = async (e) => {
        setloading(true);
        e.preventDefault();



        let res = "";
        if (editid) {
            formData._id = editid;
            formData.type = formData.title.toLowerCase().replace(/\s+/g, '-');
            formData.url = formData.title.toLowerCase().replace(/\s+/g, '-');


            res = await masterputapi("pages", formData, token, true);
        } else {
            formData.url = formData.title.toLowerCase().replace(/\s+/g, '-');
            formData.type = formData.title.toLowerCase().replace(/\s+/g, '-');
            res = await masterpostapi("pages", formData, token, true);
        }


        if (!res.data.error) {
            setloading(false);
            handleget();
            setFormData({
                title: '',
                heading: '',
                description: '',
                type: 'Policy',
                url: '',
            })
            toast.success(`${editid ? "Data Updated Successfully" : "Data Submitted Successfully"}`);

            seteditid("");
        } else {
            setloading(false)
            toast.error(`${editid ? "Data Not Updated Successfully" : "Data Not Submitted Successfully"}`)
        }
    };

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setFormData((prevData) => ({
            ...prevData,
            description: data,
        }));
    };

    return (
        <>
            {loading && <Loader />}
            <section>
                <div className="container">
                    <form action="" onSubmit={handleSubmit}>
                        <div className="grid grid-cols-3 gap-3">
                            <div className="col-span-3">
                                <TitleComp title="Pages" />
                            </div>
                            <div className="col-span-1">
                                <label htmlFor="title">Title</label>
                                <input
                                    type="text"
                                    id="title"
                                    name="title"
                                    value={formData.title}
                                    onChange={handleChange}
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                />
                            </div>
                            <div className="col-span-1">
                                <label htmlFor="heading">Heading</label>
                                <input
                                    type="text"
                                    id="heading"
                                    name="heading"
                                    value={formData.heading}
                                    onChange={handleChange}
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                />
                            </div>
                            {/* <div className="col-span-1">
                                <label htmlFor="type">Type</label>
                                <input
                                    type="text"
                                    id="type"
                                    name="type"
                                    value={formData.type}
                                    onChange={handleChange}
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                />
                            </div> */}
                            {/* <div className="col-span-1">
                                <label htmlFor="url">URL</label>
                                <input
                                    type="text"
                                    id="url"
                                    name="url"
                                    value={formData.url}
                                    onChange={handleChange}
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                />
                            </div> */}
                            <div className="col-span-3">
                                <label htmlFor="description">Description</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={formData.description}
                                    onChange={handleEditorChange}
                                    className="custom-ckeditor"
                                />
                            </div>
                            <div className="col-span-3">
                                <button className="btn bg-blue-500 text-white px-6 rounded-md py-3">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>

            <section>
                <div className="container">
                    <div className="grid grid-cols-1">

                        <div className="cols-span-1">
                            <TableComp data={data} columns={columns} getRowId={getRowId} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Policy;
