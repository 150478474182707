export const menuData = [
    { name: "Dashboard", icon: <i className="fa-solid fa-house"></i>, link: "/dashboard", type: "Dashboard" },
    { name: "Master Products", icon: <i className="fa-solid fa-boxes-packing"></i>, link: "/masterproduct", type: "Products" },
    { name: "Order History", icon: <i className="fa-solid fa-clock-rotate-left"></i>, link: "/orderhistory", type: "OrderHistory" },
    { name: "User", icon: <i className="fa-solid fa-user"></i>, link: "/user", type: "User" },


    { name: "User Type", icon: <i className="fa-solid fa-users"></i>, link: "/usertype", type: "UserTypes" },
    { name: "Permission", icon: <i className="fa-solid fa-key"></i>, link: "/permission", type: "ModulePermissions" },
    { name: "Stores", icon: <i class="fa-solid fa-store"></i>, link: "/stores", type: "Stores" },


    { name: "Positions", icon: <i className="fa-solid fa-chart-line"></i>, link: "/positions", type: "Positions" },
    { name: "Modules", icon: <i className="fa-solid fa-boxes-stacked"></i>, link: "/modules", type: "Modules" },
    { name: "App Category", icon: <i className="fa-solid fa-list"></i>, link: "/appcategory", type: "AppCategory" },
    { name: "Entity Type", icon: <i className="fa-solid fa-file"></i>, link: "/documenttype", type: "VendorDocuments" },
    { name: "Document Name", icon: <i className="fa-solid fa-book"></i>, link: "/documentname", type: "VendorDocuments" },
    { name: "Brands", icon: <i className="fa-solid fa-b"></i>, link: "/brand", type: "Brands" },
    { name: "Units", icon: <i className="fa-brands fa-unity"></i>, link: "/units", type: "Units" },
    { name: "Add Coupons", icon: <i className="fa-solid fa-ticket"></i>, link: "/coupon", type: "Coupons" },
    { name: "Coupons List", icon: <i className="fa-solid fa-money-bill"></i>, link: "/couponlist", type: "Coupons" },
    { name: "Subscription", icon: <i className="fa-solid fa-indian-rupee-sign"></i>, link: "/subscription", type: "Subscription" },
    { name: "Banner", icon: <i className="fa-solid fa-sliders"></i>, link: "/slider", type: "Slider" },
    { name: "Pages", icon: <i className="fa-regular fa-file-lines"></i>, link: "/pages", type: "Pages" },
    { name: "FAQ", icon: <i class="fa-regular fa-circle-question"></i>, link: "/faq", type: "FAQ" },

    // { name: "Settings", icon: <i className="fa-solid fa-gear"></i>, link: "/setting", type: "all" },

    { name: "Logout", icon: <i className="fa-solid fa-right-from-bracket"></i>, link: "/login", type: "all", isLogout: true },
];