import React, { useEffect, useState } from 'react';
import TitleComp from '../../component/TitleComp';
import { mastergetapi, superadmingetapi, superadminpostapi } from '../../Api/Api';
import Loader from '../../component/Loader';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function AddUser() {
    // State to store form values
    const [loading, setloading] = useState(false);
    const [usertypedata, setusertypedata] = useState([]);
    const [rolesdata, setrolesdata] = useState([])
    const [positiondata, setpositiondata] = useState([])
    const [formData, setFormData] = useState({
        phone: '',
        dob: '',
        gender: '',
        password: "",
        usertype: "", // This will store the MongoDB _id
        panNumber: '',
        panImage: null,
        role: '',     // for staff role
        position: '', // for staff position
    });

    let token = localStorage.getItem("token");

    // State to control visibility of roles and position fields
    const [isStaff, setIsStaff] = useState(false);

    // Handle input changes
    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        if (type === 'file') {
            setFormData({ ...formData, [name]: files[0] });
        } else {
            setFormData({ ...formData, [name]: value });

            // Check if the selected usertype is 'staff' based on its MongoDB _id
            if (name === 'usertype') {
                const selectedType = usertypedata?.find(item => item._id === value);
                if (selectedType && selectedType.name === 'staff') {
                    setIsStaff(true);
                } else {
                    setIsStaff(false);
                }
            }
        }
    };


    const navigate = useNavigate()

    const handleusertype = async () => {
        let res = await mastergetapi('user_type', token);
        setusertypedata(res.data);

        if (res.data.error) {
            toast.error(res.data.misc)
            setloading(false)
        }

    };

    const handleposition = async () => {
        let res = await mastergetapi('positions', token);
        setpositiondata(res.data);
    };

    const handleroles = async () => {
        let res = await mastergetapi('module_permission', token);
        setrolesdata(res.data);
    };

    useEffect(() => {
        handleusertype();
        handleposition()
        handleroles()
    }, []);

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Create the object with raw form data
        const rawData = {
            phone: formData.phone,
            dob: formData.dob,
            password: formData.password,
            gender: formData.gender,
            user_type: formData.usertype, // Send usertype as MongoDB _id
            pan_no: formData.panNumber,
        };

        // Add staff-specific fields if applicable
        if (isStaff) {
            rawData.roles = formData.role;
            rawData.position = formData.position;
        }

        // Set loading state
        setloading(true);

        // Send the data as raw JSON
        try {
            let res = await superadminpostapi(`store_other_user`, rawData, token);
            if (!res.error) {
                toast.success("Data Submitted Successfully");
                navigate('/user')
            } else {
                toast.error("Error submitting data");
            }
        } catch (error) {
            toast.error("An error occurred");
        } finally {
            setloading(false);
        }
    };


    return (
        <>
            {loading && <Loader />}
            <section>
                <div className="container">
                    <div className="grid grid-cols-3">
                        <div className="col-span-3">
                            <TitleComp title="Add User" />
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-3 gap-10">
                            <div className="col-span-1">
                                <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                                    Phone
                                </label>
                                <input
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    minLength={10}
                                    maxLength={10}
                                    value={formData.phone}
                                    onChange={handleChange}
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                            </div>
                            <div className="col-span-1">
                                <label htmlFor="usertype" className="block text-sm font-medium text-gray-700">
                                    User Type
                                </label>
                                <select
                                    id="usertype"
                                    name="usertype"
                                    value={formData.usertype}
                                    onChange={handleChange}
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                >
                                    <option value="">Select User Type</option>
                                    {usertypedata?.map((item) => (
                                        <option key={item._id} value={item._id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-span-1">
                                <label htmlFor="dob" className="block text-sm font-medium text-gray-700">
                                    Dob
                                </label>
                                <input
                                    type="date"
                                    id="dob"
                                    name="dob"
                                    value={formData.dob}
                                    onChange={handleChange}
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                            </div>
                            <div className="col-span-1">
                                <label htmlFor="dob" className="block text-sm font-medium text-gray-700">
                                    Password
                                </label>
                                <input
                                    type="text"
                                    id="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                            </div>
                            <div className="col-span-1">
                                <label htmlFor="gender" className="block text-sm font-medium text-gray-700">
                                    Gender
                                </label>
                                <select
                                    id="gender"
                                    name="gender"
                                    value={formData.gender}
                                    onChange={handleChange}
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                >
                                    <option value="">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                            {/* <div className="col-span-1">
                                <label htmlFor="panNumber" className="block text-sm font-medium text-gray-700">
                                    Pan Number
                                </label>
                                <input
                                    type="text"
                                    id="panNumber"
                                    name="panNumber"
                                    value={formData.panNumber}
                                    onChange={handleChange}
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                            </div>
                            <div className="col-span-1">
                                <label htmlFor="panImage" className="block text-sm font-medium text-gray-700">
                                    Pan Image
                                </label>
                                <input
                                    type="file"
                                    id="panImage"
                                    name="panImage"
                                    onChange={handleChange}
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                            </div> */}

                            {/* Conditionally render Role and Position fields if usertype is 'staff' */}
                            {isStaff && (
                                <>
                                    <div className="col-span-1">
                                        <label htmlFor="position" className="block text-sm font-medium text-gray-700">
                                            Position
                                        </label>

                                        <select
                                            id="position"
                                            name="position"
                                            value={formData.position}
                                            onChange={handleChange}
                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                        >
                                            <option value="">Select Positions</option>
                                            {positiondata.map((item) => (
                                                <option key={item._id} value={item._id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-span-1">
                                        <label htmlFor="role" className="block text-sm font-medium text-gray-700">
                                            Role
                                        </label>

                                        <select
                                            id="role"
                                            name="role"
                                            value={formData.role}
                                            onChange={handleChange}
                                            className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                        >
                                            <option value="">Select Positions</option>
                                            {rolesdata.map((item) => (
                                                <option key={item._id} value={item._id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                </>
                            )}
                        </div>
                        <div className="col-span-3 mt-4">
                            <button
                                type="submit"
                                className="px-4 py-2 bg-blue-500 text-white rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </section>
        </>
    );
}

export default AddUser;
