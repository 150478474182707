import React from 'react'
import GetPage from '../component/GetPage'

function Brands() {
    return (
        <>
            <GetPage title="Brands" url="brands" roletitle="Brands" value="Write" />
        </>
    )
}

export default Brands
