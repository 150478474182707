import React from 'react'
import GetPage from '../component/GetPage'

function Units() {
    return (
        <>
            <GetPage title="Units" url="units" roletitle="Units" value="Write" />
        </>
    )
}

export default Units
