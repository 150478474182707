import React, { useEffect, useState } from 'react'
import TableComp from '../component/TableComp'
import { mastergetapi, masterputapi } from '../Api/Api'
import TitleComp from '../component/TitleComp'
import convertDateToMonthName from '../component/FormDate'
import { useNavigate } from 'react-router-dom'
import Loader from './Loader'
import { toast } from 'react-toastify'
import ConfirmPopup from './ConfirmPopup'
import { hasPermission } from '../Access/Permission'

function GetPage(props) {
    const [data, setdata] = useState([])
    const [delid, setdelid] = useState("")
    let token = localStorage.getItem("token")
    const navigate = useNavigate()
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [loading, setloading] = useState(false)

    const handlePermission = async () => {
        setloading(true)
        let res = await mastergetapi(props.url, token)

        if (!res.error) {
            setdata(res.data)
            setloading(false)
        } else {
            setloading(false)
        }

    }

    useEffect(() => {
        handlePermission()
    }, [])
    const columns = [
        {
            field: "name",
            headerName: "Name",
            flex: 1,
        },
        {
            field: "created_at",
            headerName: "Created Date",
            flex: 1,
            renderCell: (params) => { // Corrected renderCell casing
                return <span>{convertDateToMonthName(params.row.created_at)}</span>;
            },
        },

        ...(hasPermission(`${props.roletitle}`, 'Update') ? [{
            field: "edit",
            headerName: "Edit",
            flex: 1,
            renderCell: (params) => {
                return (
                    <button className='cutombtn' onClick={() => handleNavigation(params.row)}>
                        <i className="fa-solid fa-pen-to-square"></i>
                    </button>
                );
            },
        }] : []),

        ...(hasPermission(`${props.roletitle}`, 'Delete') ? [{
            field: "delete",
            headerName: "Delete",
            flex: 1,
            renderCell: (params) => { // Corrected renderCell casing
                return <button className='cutombtn' onClick={(e) => showDeleteConfirmation(params.row)}><i className="fa-solid fa-trash"></i></button>;
            },
        }] : []),


    ];

    const showDeleteConfirmation = (item) => {
        setdelid(item._id);
        setConfirmDelete(true);
    }

    const handledelete = async (item) => {

        setloading(true)
        let requestdata = {
            _id: delid,
            deleted_at: "1"
        }
        let res = await masterputapi(props.url, requestdata, token)
        if (!res.error) {

            setloading(false)
            toast.success("Data deleted Succesfully")
        } else {
            setloading(false)
        }

    }


    const handleDeleteConfirmed = async () => {
        handledelete()
        setConfirmDelete(false)
    }

    const handleNavigation = (item) => {
        // Replace spaces with underscores in the URL and title

        const formattedTitle = props.title.replace(/\s+/g, '_');

        // Navigate to the desired path with the formatted URL and title
        navigate(`/editpage/${props.url}/${formattedTitle}`, { state: item });
    };

    const getRowId = (row) => row._id
    return (
        <>
            {loading && <Loader />}
            {confirmDelete && (
                <ConfirmPopup

                    onConfirm={handleDeleteConfirmed}
                    onCancel={() => setConfirmDelete(false)}
                />
            )}
            <section>
                <div className="container">
                    <div className="grid grid-cols-1">
                        <div className="cols-span-1">
                            <TitleComp title={props.title} />
                        </div>
                        {hasPermission(`${props?.roletitle}`, `${props?.value}`) && <div className="cols-span-1">
                            <div className="text-end mb-2">
                                <button className='btn bg-blue-500 px-5 py-2 rounded-md text-white' onClick={(e) => navigate(`/addpage/${props.url}/${props.title}`)}>Add {props.title}</button>
                            </div>
                        </div>}
                        <div className="cols-span-1">
                            <TableComp data={data} columns={columns} getRowId={getRowId} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default GetPage
