import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "../assets/css/login.css";
import logo from "../assets/images/logo.png";
import { postapi, staffloginapi, superadminloginapi } from '../Api/Api';
import { toast, ToastContainer } from 'react-toastify';
import OTPInput from 'react-otp-input';
import Loader from '../component/Loader';
import { menuData } from '../Data/Menudata';

const Login = () => {
    const [phone, setPhone] = useState("");
    const [pass, setpass] = useState("")
    const [loading, setloading] = useState(false)

    const [type, settype] = useState("")

    const [check, setCheck] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [otp, setOtp] = useState("");
    const [checkOtp, setCheckOtp] = useState("");
    const [isVisible, setIsVisible] = useState(false);
    const [showSplash, setShowSplash] = useState(true);
    const [circleStyle, setCircleStyle] = useState({
        height1: '50vh',
        width1: '53vw',
        height2: '60vh',
        width2: '50vw',
        padding: '30px',
    });

    const navigate = useNavigate();

    useEffect(() => {
        const splashTimer = setTimeout(() => {
            setShowSplash(false);
        }, 2000);

        return () => clearTimeout(splashTimer);
    }, []);

    useEffect(() => {
        if (!showSplash) {
            const timer = setTimeout(() => {
                setIsVisible(true);
            }, 100); // Delay to show the effect

            return () => clearTimeout(timer);
        }
    }, [showSplash]);

    useEffect(() => {
        if (showSplash) {
            const animationTimer = setTimeout(() => {
                setCircleStyle({
                    height1: '400vh',
                    width1: '400vw',
                    height2: '400vh',
                    width2: '400vw',
                    padding: '150px',
                });
            }, 400);
            return () => clearTimeout(animationTimer);
        }
    }, [showSplash]);

    const handleNumber = async (e) => {
        e.preventDefault();
        setloading(true)
        let request = { phone: phone, password: pass };
        let res = ""
        if (type == "Staff") {
            res = await staffloginapi('login', request);
        } else {
            res = await superadminloginapi('login', request);
        }
        if (res?.data?.error) {
            setloading(false)
            toast.error("Oops! We couldn't log you in. Please check your credentials and try again.");
        } else {
            if (!res.error) {

                localStorage.setItem("user_id", res.data?.user_id);
                localStorage.setItem("token", res.data?.token);
                localStorage.setItem("usertypeid", res.data.user_type?._id);
                localStorage.setItem("username", res.data.user_type?.name);
                // navigate("/dashboard");

                const roles = res.data.roles.reduce((acc, role) => {
                    acc[role.type.name] = role.value;
                    return acc;
                }, {});




                if (roles['Dashboard'] && roles['Dashboard'].includes('Read')) {
                    navigate("/dashboard");
                } else {

                    const readableRoles = Object.entries(roles)
                        .filter(([role, permissions]) => permissions.includes('Read'))
                        .map(([role]) => ({ role }));

                    if (readableRoles.length > 0) {

                        await getLinkByRoleType(readableRoles);
                    } else {
                        toast.error("No accessible roles found.");
                    }
                }



                // Save the roles object to localStorage as a string
                localStorage.setItem('roles', JSON.stringify(roles));
                setloading(false)
                // setCheckOtp(res.data);
                setloading(false)
            } else {
                toast.error("Oops! We couldn't log you in. Please check your credentials and try again.");
                setloading(false)
            }
        }

    };

    const getLinkByRoleType = async (readableRoles) => {

        const similarItems = menuData.filter(item => readableRoles.some(otherItem => otherItem.role == item.type));
        if (similarItems.length > 0) {
            navigate(similarItems[0].link)
        }
    };




    // const handleOtp = async (e) => {

    //     e.preventDefault();
    //     setloading(true)
    //     if (checkOtp == otp) {
    //         let request = { phone: phone, otp: otp };
    //         let res = await superadminloginapi('verify_otp', request);
    //         if (res.misc == "Old") {
    //             localStorage.setItem("user_id", res.data.user_id);
    //             localStorage.setItem("token", res.data.token);
    //             localStorage.setItem("usertypeid", res.data.user_type._id);
    //             localStorage.setItem("username", res.data.user_type.name);
    //             navigate("/dashboard");
    //             setloading(false)
    //         }
    //     } else {
    //         toast.error("Otp Mismatch");
    //         setloading(false)
    //     }
    // };

    return <>
        {loading && <Loader />}
        {showSplash ? (
            <div className="splash-container overflow-hidden">
                <div className="splash-content">
                    <img src={logo} alt="Logo" className="logo" />
                    <h4>STORO</h4>
                </div>
                <div className="animated-circle" style={{ ...circleStyle, right: '-70px', top: '-70px' }}></div>
                <div className="animated-circle" style={{ ...circleStyle, left: '-90px', bottom: '-80px' }}></div>
            </div>
        ) : (
            <div className={`flex justify-center items-center min-h-screen ${isVisible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-1000 ease-in-out`}>
                <ToastContainer />
                <div className="loginbox bg-white p-6 rounded-lg shadow-lg transition-transform duration-300 ease-in-out transform">
                    <div className="heading flex justify-center mb-6">
                        <div>
                            <div className="flex justify-center">
                                <img src={logo} alt="Logo" className="w-24 h-auto" />
                            </div>
                            <h4>Sign In Storo</h4>
                        </div>
                    </div>
                    <form className="form "
                        // onSubmit={!check ? handleNumber : handleOtp}
                        onSubmit={handleNumber}

                    >
                        <div className="form-group">
                            <label htmlFor="type" className="block text-gray-700">Type</label>

                            <select name="" required value={type}
                                onChange={(e) => settype(e.target.value)} className="input w-full p-2 border border-gray-300 rounded-md shadow-sm transition-opacity duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500" id="">
                                <option value="" className='hidden'>Select Type</option>
                                <option value="Staff">Staff</option>
                                <option value="Admin">SuperAdmin</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="phone" className="block text-gray-700">Phone</label>
                            <input
                                required
                                className="input w-full p-2 border border-gray-300 rounded-md shadow-sm transition-opacity duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                type="phone"
                                minLength={10}
                                maxLength={10}
                                name="phone"
                                id="phone"
                                placeholder="Enter Your Phone Number"
                            />
                        </div>
                        <div className="form-group ">
                            <label htmlFor="phone" className="block text-gray-700">Password</label>
                            <div className="relative">
                                <input
                                    required
                                    className="input w-full p-2 border border-gray-300 rounded-md shadow-sm transition-opacity duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    value={pass}
                                    onChange={(e) => setpass(e.target.value)}
                                    type={!showPassword ? "password" : "text"}

                                />
                                <div className="absolute top-[15px] right-[15px]" onClick={(e) => setShowPassword(!showPassword)}>
                                    {!showPassword ? <i className="fa-solid fa-eye"></i> : <i className="fa-solid fa-eye-slash"></i>}
                                </div>
                            </div>
                        </div>
                        {check && (
                            <div className="form-group OtpBox">
                                <label htmlFor="otp" className="block mb-3 text-gray-700">Otp</label>
                                <OTPInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={4}
                                    className="form-control"
                                    renderSeparator={<span className="me-2"> </span>}
                                    renderInput={(props) => <input {...props} />}
                                />
                            </div>
                        )}
                        <input className="login-button w-full p-2 bg-blue-500 text-white rounded-md cursor-pointer transition-transform duration-300 ease-in-out transform hover:scale-105" type="submit" value={"Submit"} />
                    </form>
                </div>
            </div >
        )}
    </>
};

export default Login;
