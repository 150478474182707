import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { superadmingetapi, superadminputapi } from '../../Api/Api';
import Notfound from '../../component/Notfound';
import Loader from '../../component/Loader';
import TitleComp from '../../component/TitleComp';
import { BASE_URL } from '../../Api/BaseUrl';
// import imagenot from "../../assets/images/notfound.avif";
// import veg from "../../assets/images/veg.png";
// import nonveg from "../../assets/images/nonveg.jpg";
// import approve from "../../assets/images/approved.png"
// import rejected from "../../assets/images/rejected.png"
import ProductComp from '../../component/ProductComp';
import { toast } from 'react-toastify';


const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
        return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
};

function ProductList() {
    const { id } = useParams();
    const [search, setsearch] = useState("")
    const [filterdata, setfilterdata] = useState([])
    const { state } = useLocation()
    const navigate = useNavigate()
    let token = localStorage.getItem("token");
    const [selectedItemId, setSelectedItemId] = useState(null);


    const handleCheckboxChange = async (itemId, status) => {
        let res = await superadmingetapi(`product_variant?product=${itemId}`, token);

        if (!res.error) {
            console.log(res.data.length)
            if (res.data.length == 0) {
                toast.error("Variant is Not Available")
            } else {
                await changecheckbox(itemId, status)
            }
        }
    };


    const changecheckbox = async (itemId, status) => {
        setloading(true);
        let requestdata = {
            _id: itemId,
            is_disabled: !status
        }
        let res = await superadminputapi(`products`, requestdata, token);

        if (!res.error) {
            setloading(false)
            handleget()
        }
        setloading(false);
    }
    const [loading, setloading] = useState(false);
    const [data, setdata] = useState([]);

    let handleget = async () => {
        setloading(true);
        let res = await superadmingetapi(`products?${state}=${id}`, token);

        if (!res.error) {
            setdata(res.data);
        }
        setloading(false);
    };

    useEffect(() => {
        handleget();
    }, []);


    const handleFilter = (e) => {
        e.preventDefault();
        const filteredData = data.filter((item) =>
            item?.name.toLowerCase().includes(search.toLowerCase())
        );

        setfilterdata(filteredData);
    };


    useEffect(() => {
        if (data) {
            setfilterdata(data)
        }
    }, [data])


    return (
        <>
            {loading && <Loader />}
            <div className="grid grid-cols-2 gap-5">
                <div className="col-span-2">
                    <TitleComp title="Product List" />
                </div>
                <div className="col-span-1">
                    <form onSubmit={handleFilter}>
                        <div className="searchbar">
                            <div className="InputContainer">
                                <input type="text" value={search} onChange={(e) => setsearch(e.target.value)} name="text" id="input" placeholder="Search" />
                                <div className="border"></div>

                                <button className="micButton"><svg viewBox="0 0 512 512" className="searchIcon"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"></path></svg>
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-span-1">
                    <button className='btn bg-blue-500 text-sm px-5 py-2 hover:bg-blue-600 hover:shadow-sm text-white rounded-md w-max-w' onClick={(e) => { setfilterdata(data); setsearch("") }}>Clear All</button>
                </div>
            </div>
            {filterdata.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                    {/* {filterdata.map((item) => {
                        let truncatedDescription = truncateText(item.description, 5);
                        return (
                            <div className="col-span-1" key={item._id}>
                                <div className="card">
                                    <div className="image-container">
                                        <img
                                            src={`${BASE_URL}${item.image?.file}`}
                                            alt={item?.name}
                                            onError={(e) => (e.target.src = imagenot)}
                                            loading="lazy"
                                            className="w-full h-full object-cover"
                                        />
                                    </div>

                                    {item.connected_app_category?.name == "Restaurants" && <label className="favorite">
                                        <img src={item.veg ? veg : nonveg} alt="" />
                                    </label>}

                                    <div className="content">
                                        <div className="brand flex justify-between">
                                            <div className="">
                                                {item.brands?.name} {item?.connected_app_category?.name && <span className="inline-block ms-2 bg-emerald-400 text-white text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
                                                    {item.connected_app_category?.name}
                                                </span>}
                                            </div>

                                            <label htmlFor="" className="enablecheck bg-white">
                                                <div className="checkbox-wrapper-19">
                                                    <input
                                                        id={`cbtest-19-${item._id}`}
                                                        type="checkbox"
                                                        checked={!item.is_disabled}
                                                        onChange={() => handleCheckboxChange(item._id, item.is_disabled)}
                                                    />
                                                    <label className="check-box" htmlFor={`cbtest-19-${item._id}`}></label>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="product-name mt-2" style={{ marginBottom: '5px' }}>
                                            <h3 className="text-sm min-h-10">{item?.name}</h3>
                                            <div className="flex flex-row gap-3 ">
                                                <div className="flex items-center ">
                                                    <h6 className="font-semibold">Add Ons Applcable</h6>
                                                    <img
                                                        src={item.addons_applicable ? approve : rejected}
                                                        style={{ height: "30px" }}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="flex items-center ">
                                                    <h6 className="font-semibold">Sides Applicable</h6>
                                                    <img
                                                        src={item.sides_applicable ? approve : rejected}
                                                        style={{ height: "30px" }}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="product-name min-h-10">
                                            <p dangerouslySetInnerHTML={{ __html: truncatedDescription }}></p>
                                        </div>
                                    </div>
                                    <div className="mb-3 p-2" >
                                        <button className="btn bg-blue-500 text-sm w-full p-2 text-white rounded-md" onClick={(e) => navigate(`/user/productvariant/${item._id}`)}>
                                            <i className="fa-solid fa-circle-info"></i>    See Product Variant
                                        </button>
                                    </div>
                                </div>
                            </div>
                        );
                    })} */}
                    <ProductComp data={filterdata} handleCheckboxChange={handleCheckboxChange} />
                </div>
            ) : (
                <div className="grid grid-cols-3 justify-center">
                    <div className="col-span-1"></div>
                    <div className="col-span-1">
                        <Notfound title="Product Not Found" />
                    </div>
                    <div className="col-span-1"></div>
                </div>
            )}
        </>
    );
}

export default ProductList;
