


import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom'
import Layout from './layout'

import "./App.css"
import 'react-toastify/dist/ReactToastify.css';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Permission from './pages/PermissionSection/Permission';
import Modules from './pages/Modules';
import Setting from './pages/Setting';
import Position from './pages/Position';
import Usertype from './pages/Usertype';

import DocumentName from './pages/DocumentName/DocumentName';
import DocumentType from './pages/DocumentType';
import Brands from './pages/Brands';
import Units from './pages/Units';
import AddPage from './component/AddPage';
import AddPermission from './pages/PermissionSection/AddPermission';
import AddDocumentName from './pages/DocumentName/AddDocumentName';
import AppCategory from './pages/AppCategorySection/AppCategory';
import ChildCategory from './pages/AppCategorySection/ChildCategory';
import User from './pages/UserSection/User';
import Userinfo from './pages/UserSection/Userinfo';
import AddCoupon from './pages/Coupons/AddCoupon';
import CouponList from './pages/Coupons/CouponList';
import OrderHistory from './pages/Orders/OrderHistory';
import Stores from './pages/Stores/Stores';
import ProductList from './pages/Product/ProductList';
import ProductVariantList from './pages/Product/ProductVariantList';
import OrderDetail from './pages/Orders/OrderDetail';
import AddSubscription from './pages/Subscription/AddSubscription';
import Slider from './pages/Slider/Slider';
import "./assets/css/searchbar.css"
import Invoice from './pages/InvoicePage/Invoice';
import Policy from './pages/PolicyPage/Policy';
import AddUser from './pages/UserSection/AddUser';
import EditUser from './pages/UserSection/EditUser';
import MasterProduct from './pages/Product/MasterProduct';
import MasterProductVar from './pages/Product/MasterProductVar';
import ProfilePage from './pages/Profile/ProfilePage';
import StoresList from './pages/Stores/StoresList';
import Faq from './pages/Faq';
import ProductCategory from './pages/AppCategorySection/ProductCategory';
import AddProductCategory from './pages/AppCategorySection/AddProductCategory';







function App() {

  const routes = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path='/' element={<Layout />}>

          <Route index element={<Navigate to="/dashboard" />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/permission' element={<Permission />} />
          <Route path='/user' element={<User />} />
          <Route path='/dashboard/:id' element={<User />} />


          <Route path='/edituser' element={<EditUser />} />
          <Route path='/stores' element={<StoresList />} />





          <Route path='/adduser' element={<AddUser />} />

          <Route path='/modules' element={<Modules />} />
          <Route path='/setting' element={<Setting />} />
          <Route path='/positions' element={<Position />} />
          <Route path='/usertype' element={<Usertype />} />
          <Route path='/appcategory' element={<AppCategory />} />
          <Route path='/childappcategory/:id' element={<ChildCategory />} />

          <Route path='/documenttype' element={<DocumentType />} />
          <Route path='/documentname' element={<DocumentName />} />
          <Route path='/brand' element={<Brands />} />
          <Route path='/units' element={<Units />} />
          <Route path='/orderdetail' element={<OrderDetail />} />

          <Route path='/addpermission/:id?' element={<AddPermission />} />
          <Route path='/profile' element={<ProfilePage />} />


          <Route path='/adddocumentname' element={<AddDocumentName />} />
          <Route path='/editpage/:url/:title/:parent?' element={<AddPage />} />
          <Route path='/addpage/:url/:title/:parent?' element={<AddPage />} />
          <Route path='/userinfo' element={<Userinfo />} />



          <Route path='/coupon' element={<AddCoupon />} />
          <Route path='/couponlist' element={<CouponList />} />
          <Route path='/subscription' element={<AddSubscription />} />
          <Route path='/slider' element={<Slider />} />




          <Route path='/orderhistory' element={<OrderHistory />} />
          <Route path='/invoice' element={<Invoice />} />
          <Route path='/pages' element={<Policy />} />


          <Route path='/user/stores/:id' element={<Stores />} />
          <Route path='/stores/storeinfo/:id' element={<Stores />} />
          <Route path='/faq' element={<Faq />} />


          <Route path='/user/product/:id' element={<ProductList />} />
          <Route path='/addproductcategory/:id' element={<AddProductCategory />} />



          <Route path='/productcategory/:id' element={<ProductCategory />} />

          <Route path='/user/productvariant/:id' element={<ProductVariantList />} />
          <Route path='/masterproduct' element={<MasterProduct />} />
          <Route path='/masterproduct/variant/:id' element={<MasterProductVar />} />





        </Route>
        <Route path='/login' element={<Login />} />
      </>
    )
  )

  return (
    <>

      <RouterProvider router={routes} />

    </>
  )
}

export default App







