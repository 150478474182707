import React, { useEffect, useState } from 'react'
import TableComp from '../../component/TableComp'
import { mastergetapi } from '../../Api/Api'
import TitleComp from '../../component/TitleComp'
import convertDateToMonthName from '../../component/FormDate'
import { useNavigate } from 'react-router-dom'
import { hasPermission } from '../../Access/Permission'

function Permission() {
    const [data, setdata] = useState([])
    let token = localStorage.getItem("token")

    const handlePermission = async () => {
        let res = await mastergetapi('module_permission', token)

        setdata(res.data)
    }

    const navigate = useNavigate()

    useEffect(() => {
        handlePermission()
    }, [])
    const columns = [
        {
            field: "name",
            headerName: "Name",
            flex: 1,
        },
        {
            field: "created_at", // Corrected field name
            headerName: "Created Date",
            flex: 1,
            renderCell: (params) => { // Corrected renderCell casing
                return <span>{convertDateToMonthName(params.row.created_at)}</span>;
            },
        },


        ...(hasPermission(`ModulePermissions`, 'Update') ? [{
            field: "edit",
            headerName: "Edit",
            flex: 1,
            renderCell: (params) => { // Corrected renderCell casing
                return <button className='cutombtn' onClick={(e) => handleNavigation(params.row)}><i className="fa-solid fa-pen-to-square"></i></button>;
            },
        }] : []),
    ];

    const handleNavigation = (item) => {
        navigate(`/addpermission`, { state: item })
    }

    const getRowId = (row) => row._id
    return (
        <>
            <section>
                <div className="container">
                    <div className="grid grid-cols-1">
                        <div className="cols-span-1">
                            <TitleComp title="Module Permission" />
                        </div>
                        {hasPermission('ModulePermissions', 'Write') && <div className="cols-span-1">
                            <div className="text-end mb-2">
                                <button className='btn bg-blue-500 px-5 py-2 rounded-md text-white' onClick={(e) => navigate(`/addpermission`)}>Add Module Permission</button>
                            </div>
                        </div>}
                        <div className="cols-span-1">
                            <TableComp data={data} columns={columns} getRowId={getRowId} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Permission
