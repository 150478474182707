import React from 'react'
import { BASE_URL } from '../Api/BaseUrl';
import imagenot from "../assets/images/notfound.avif";
import veg from "../assets/images/veg.png";
import nonveg from "../assets/images/nonveg.jpg";
import approve from "../assets/images/approved.png"
import rejected from "../assets/images/rejected.png"
import convertDateToMonthName from './FormDate';
import { hasPermission } from '../Access/Permission';


function ProductVariantComp({ data, handleCheckboxChange }) {
    return (
        <>
            {data.map((item) => {
                // let truncatedDescription = truncateText(item.description, 5);
                return (
                    <div className="col-span-1" key={item._id}>

                        <>
                            <div className="col-span-1">
                                <div className="card">
                                    <div className="image-container">

                                        <img
                                            src={`${BASE_URL}${item.product?.image?.file}`}
                                            alt={item.name}
                                            loading='lazy'
                                            onError={(e) => e.target.src = imagenot}
                                            className="w-full h-full object-cover"
                                        />

                                        <div className="price">  <del className='text-red-600'>₹{item.mrp}</del> <span className='text-emerald-600'>₹{item.selling_price}</span></div>
                                    </div>

                                    {item?.connected_app_category?.name == "Restaurants" && <label className="favorite">
                                        <img src={item.veg ? veg : nonveg} alt="" />
                                    </label>}

                                    <div className="content">
                                        <div className="brand flex justify-between">
                                            <div className="">
                                                {item?.store?.name}
                                            </div>
                                            {hasPermission("Products", "Update") && <label htmlFor="" className="enablecheck bg-white">
                                                <div className="checkbox-wrapper-19">
                                                    <input
                                                        id={`cbtest-19-${item._id}`}
                                                        type="checkbox"
                                                        checked={!item.is_disabled}
                                                        onChange={() => handleCheckboxChange(item._id, item.is_disabled)}
                                                    />
                                                    <label className="check-box" htmlFor={`cbtest-19-${item._id}`}></label>
                                                </div>
                                            </label>}
                                        </div>
                                        <div className="product-maintitle mb-1  " >{item.name}  <span className='text-emerald-400 text-lg'> ({item.unit_weight}{item.units.name != "other" && item.units.name})  </span></div>

                                        <div className="product-name">
                                            <span>HSN : {item.hsn_number}</span>
                                            <h6 className='text-gray-600'>Created At : {convertDateToMonthName(item.created_at)}</h6>
                                        </div>




                                    </div>

                                    {/* <div className="mb-3">
                                                <div className={`flex gap-6 ps-2 `}>

                                                    {item.user.name && <div className="uiverse">
                                                        <span className="tooltip1">User</span>
                                                        <span className='tooltipname'>
                                                            <i className="fa-solid fa-user"></i> {item.user.name}
                                                        </span>
                                                    </div>}

                                                    {item.vendor.name && <div className="uiverse">
                                                        <span className="tooltip1">Vendor</span>
                                                        <span className='tooltipname'>
                                                            <i className="fa-solid fa-shop"></i> {item.vendor.name}
                                                        </span>
                                                    </div>}
                                                </div>
                                            </div> */}




                                </div>
                            </div>
                        </>


                    </div>
                );
            })}
        </>
    )
}

export default ProductVariantComp
