import React, { useEffect, useState } from 'react'
import TableComp from '../../component/TableComp'
import { mastergetapi } from '../../Api/Api'
import TitleComp from '../../component/TitleComp'
import convertDateToMonthName from '../../component/FormDate'
import { useNavigate } from 'react-router-dom'
import { hasPermission } from '../../Access/Permission'
import Modal from '../../component/Modal'

function DocumentName() {
    const [data, setdata] = useState([])
    const [show, setshow] = useState(false)
    const [infodata, setinfodata] = useState([])
    let token = localStorage.getItem("token")
    const navigate = useNavigate()

    const handleDocumentName = async () => {
        let res = await mastergetapi('document_names', token)

        setdata(res.data)
    }

    useEffect(() => {
        handleDocumentName()
    }, [])
    const columns = [
        {
            field: "name",
            headerName: "Name",
            flex: 1,
        },

        {
            field: "created_at", // Corrected field name
            headerName: "Created Date",
            flex: 1,
            renderCell: (params) => { // Corrected renderCell casing
                return <span>{convertDateToMonthName(params.row.created_at)}</span>;
            },
        },
        {
            field: "view",
            headerName: "View",
            flex: 1,
            renderCell: (params) => { // Corrected renderCell casing
                return <button className='cutombtn' onClick={(e) => handleinfo(params.row)}><i className="fa-solid fa-eye"></i></button>;
            },
        },
        {
            field: "edit",
            headerName: "Edit",
            flex: 1,
            renderCell: (params) => { // Corrected renderCell casing
                return <button className='cutombtn' onClick={(e) => handleNavigation(params.row)}><i className="fa-solid fa-pen-to-square"></i></button>;
            },
        },
    ];


    const handleinfo = (item) => {
        setshow(true)
        setinfodata(item)

    }


    const handleNavigation = (item) => {

        navigate(`/adddocumentname`, { state: item });
    };

    const getRowId = (row) => row._id


    const content = (
        <>
            {infodata && <>
                <h3 className='font-semibold text-md mb-2'>Entity Type</h3>
                <div className="flex flex-wrap gap-2 mb-6">
                    {infodata?.document_type?.map((item) => {
                        return (
                            <span
                                key={item._id}
                                className="bg-blue-100 text-blue-800 text-sm font-medium px-3 py-1 rounded-full">
                                {item.name}
                            </span>
                        );
                    })}
                </div>





                <h4 className='font-semibold text-md mb-2'>Required In</h4>
                <div className="mb-6">
                    {infodata?.required_in?.map((item) => (
                        <span key={item._id} className="bg-green-100 text-green-800 text-sm font-medium px-3 py-1 rounded-full">
                            {item.name}
                        </span>
                    ))}
                </div>

                <h4 className='font-semibold text-md mb-2'>Used In</h4>
                <div className="flex flex-wrap gap-2">
                    {infodata?.used_in?.map((item) => {
                        return (
                            <span
                                key={item._id}
                                className="bg-purple-100 text-purple-800 text-sm font-medium px-3 py-1 rounded-full">
                                {item.name}
                            </span>
                        );
                    })}
                </div>

                <h4 className='font-semibold text-md mt-4'>Fields</h4>
                <div className="grid grid-cols-2 gap-4 mb-6">
                    {infodata?.fields?.map((field) => {
                        return (
                            <div key={field._id} className="bg-gray-100 p-3 rounded-lg shadow-sm">
                                <p className="font-medium">Field Name: {field.name}</p>
                                <p className="text-sm text-gray-600">Type: {field.type}</p>
                            </div>
                        );
                    })}
                </div></>}
        </>
    );




    return (
        <>
            {show && <Modal onCancel={() => setshow(false)} content={content} title={"Document Information"} />}
            <section>
                <div className="container">
                    <div className="grid grid-cols-1">
                        <div className="cols-span-1">
                            <TitleComp title=" DocumentName" />
                        </div>
                        {hasPermission('VendorDocuments', 'Write') && <div className="cols-span-1">
                            <div className="text-end mb-2">
                                <button className='btn bg-blue-500 px-5 py-2 rounded-md text-white' onClick={(e) => navigate(`/adddocumentname`)}>Add Document</button>
                            </div>
                        </div>}
                        <div className="cols-span-1">
                            <TableComp data={data} columns={columns} getRowId={getRowId} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DocumentName
