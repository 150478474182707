export const hasPermission = (role, permission) => {
    const userRolesString = localStorage.getItem('roles');


    if (!userRolesString) {
        console.error('No user roles found in localStorage');
        return false;
    }

    const userRoles = JSON.parse(userRolesString);


    return userRoles[role] && userRoles[role].includes(permission);
};
