import React, { useEffect, useState } from 'react'
import TableComp from '../../component/TableComp'
import { mastergetapi, masterpostapi, masterputapi } from '../../Api/Api'
import TitleComp from '../../component/TitleComp'
import convertDateToMonthName from '../../component/FormDate'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { toast } from 'react-toastify'
import Loader from '../../component/Loader'
import { hasPermission } from '../../Access/Permission'
import { BASE_URL } from '../../Api/BaseUrl'

function AddProductCategory() {
    const { state } = useLocation()
    const { id } = useParams()
    const [loading, setloading] = useState(false)
    const [name, setName] = useState("")
    const [image, setImage] = useState(null)
    let token = localStorage.getItem("token")
    const [editimg, seteditimg] = useState('')
    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        e.preventDefault()
        setloading(true)
        let formdata = new FormData()
        if (image) {
            formdata.append("image", image)
        }
        formdata.append("connected_app_category", id)
        formdata.append("name", name)

        let res = ""
        if (state) {
            formdata.append("_id", state._id)
            res = await masterputapi(`product_category`, formdata, token)
        } else {
            res = await masterpostapi(`product_category`, formdata, token)
        }

        if (!res.error) {
            toast.success(`Data ${state ? "Updated" : "Created"} Successfully`)
            setloading(false)
            navigate(`/productcategory/${id}`)

        } else {
            setloading(false)
        }

    }

    const handleedit = () => {
        setName(state.name)

        seteditimg(state.image.file)

    }

    useEffect(() => {
        if (state) {
            handleedit()
        }
    }, [state])

    return (
        <>
            {loading && <Loader />}
            <section>
                <div className="container">
                    <div className="grid grid-cols-1">
                        <div className="cols-span-1">
                            <TitleComp title={state ? "Edit Product Category" : "Add Product Category"} />
                        </div>
                    </div>
                    <form action="" onSubmit={(e) => handleSubmit(e)}>

                        <div className="grid grid-cols-3 gap-4">

                            <div className="col-span-1">
                                <label htmlFor="name">Name</label>
                                <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                />
                            </div>
                            <div className="col-span-1">
                                <label htmlFor="image">Image</label>
                                <input
                                    type="file"
                                    onChange={(e) => setImage(e.target.files[0])}
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                />
                            </div>
                            <div className="cols-span-1">
                                <button className='btn bg-blue-500 text-white mt-6 px-4 rounded-md py-3'>Submit</button>
                            </div>
                        </div>
                    </form>

                    <div className="grid grid-cols-3 mt-5">
                        <div className="col-span-1">
                            <img src={`${BASE_URL}${editimg}`} style={{ objectFit: "contain", height: "200px" }} className='rounded-md' alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AddProductCategory
