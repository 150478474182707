import React from 'react'
import imagenot from "../assets/images/notfound.avif";
import veg from "../assets/images/veg.png";
import nonveg from "../assets/images/nonveg.jpg";
import approve from "../assets/images/approved.png"
import rejected from "../assets/images/rejected.png"
import { BASE_URL } from '../Api/BaseUrl'
import { useLocation, useNavigate } from 'react-router-dom';
import { hasPermission } from '../Access/Permission';
const truncateText = (text, wordLimit) => {
    const words = text?.split(' ');
    if (words.length > wordLimit) {
        return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
};

function ProductComp({ data, handleCheckboxChange }) {
    const navigate = useNavigate()

    const location = useLocation()
    const master = location.pathname == "/masterproduct"


    return (
        <>
            {data.map((item) => {
                let truncatedDescription = truncateText(item.description, 5);
                return (
                    <div className="col-span-1" key={item._id}>
                        <div className="card">
                            <div className="image-container">
                                <img
                                    src={`${BASE_URL}${item.image?.file}`}
                                    alt={item?.name}
                                    onError={(e) => (e.target.src = imagenot)}
                                    loading="lazy"
                                    className="w-full h-full object-cover"
                                />
                            </div>

                            {item.connected_app_category?.name == "Restaurants" && <label className="favorite">
                                <img src={item.veg ? veg : nonveg} alt="" />
                            </label>}

                            <div className="content">
                                <div className="brand flex justify-between">
                                    <div className="">
                                        {item.brands?.name} {item?.connected_app_category?.name && <span className="inline-block ms-2 bg-emerald-400 text-white text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
                                            {item.connected_app_category?.name}
                                        </span>}
                                    </div>

                                    {hasPermission('Products', 'Update') && <label htmlFor="" className="enablecheck bg-white">
                                        <div className="checkbox-wrapper-19">
                                            <input
                                                id={`cbtest-19-${item._id}`}
                                                type="checkbox"
                                                checked={!item.is_disabled}
                                                onChange={() => handleCheckboxChange(item._id, item.is_disabled)}
                                            />
                                            <label className="check-box" htmlFor={`cbtest-19-${item._id}`}></label>
                                        </div>
                                    </label>}
                                </div>
                                <div className="product-name mt-2" style={{ marginBottom: '5px' }}>
                                    <h3 className="product-maintitle font-normal min-h-10">{item?.name}</h3>
                                    <div className="flex flex-row gap-3 ">
                                        <div className="flex items-center ">
                                            <h6 className="font-semibold">Add Ons Applcable</h6>
                                            <img
                                                src={item.addons_applicable ? approve : rejected}
                                                style={{ height: "30px" }}
                                                alt=""
                                            />
                                        </div>
                                        <div className="flex items-center ">
                                            <h6 className="font-semibold">Sides Applicable</h6>
                                            <img
                                                src={item.sides_applicable ? approve : rejected}
                                                style={{ height: "30px" }}
                                                alt=""
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="product-name min-h-10">
                                    <p dangerouslySetInnerHTML={{ __html: truncatedDescription }}></p>
                                </div>
                            </div>
                            <div className="mb-3 p-2" >
                                <button className="btn bg-blue-500 text-sm w-full p-2 text-white rounded-md" onClick={(e) => navigate(master ? `/masterproduct/variant/${item._id}` : `/user/productvariant/${item._id}`)}>
                                    <i className="fa-solid fa-circle-info"></i>    See Product Variant
                                </button>
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    )
}

export default ProductComp
