import React from 'react'
import GetPage from '../component/GetPage'

function DocumentType() {
    return (
        <>
            <GetPage title="Entity Type" url="document_types" roletitle="VendorDocuments" value="Write" />
        </>
    )
}

export default DocumentType
