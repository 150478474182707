import React from 'react'
import GetPage from '../component/GetPage'

function Usertype() {
    return (
        <>
            <GetPage title="User Type" url="user_type" roletitle="UserTypes" value="Write" />
        </>
    )
}

export default Usertype
