



import React, { useEffect, useState } from 'react';
import TitleComp from '../../component/TitleComp';
import Stepper from 'react-stepper-horizontal';
import { useLocation, useNavigate } from 'react-router-dom';
import { superadmingetapi, superadminputapi } from '../../Api/Api';
import convertDateToMonthName from '../../component/FormDate';
import { BASE_URL } from '../../Api/BaseUrl';
import notfound from "../../assets/images/notfound.avif";
import Modal from '../../component/Modal';
import { toast } from 'react-toastify';
import Invoice from '../InvoicePage/Invoice';
import Loader from '../../component/Loader';

function OrderDetail() {
    const { state } = useLocation();
    const [show, setshow] = useState(false)
    const [invoice, setinvoice] = useState(false)
    let userid = localStorage.getItem("user_id")
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [misc, setmisc] = useState([])
    const navigate = useNavigate()
    const [currentStepIndex, setCurrentStepIndex] = useState(0);

    const token = localStorage.getItem("token");
    const [allstatus, setallstatus] = useState(false)
    const [orderid, setorderid] = useState("")

    const [status, setStatus] = useState('');
    const [remark, setRemark] = useState('');

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };







    const handlestatusorder = async (e) => {
        e.preventDefault()
        setLoading(true)
        let requestdata = ""
        if (allstatus) {

            requestdata = {
                _id: state._id,
                shipping_remarks: {
                    status: status,
                    remark: remark,
                    user: userid
                },

                item_order_status: status,

            }
        } else {
            requestdata = {
                _id: orderid,
                shipping_remarks: {
                    status: status,
                    remark: remark,
                    user: userid
                },

                item_order_status: status,

            }
        }
        let res = await superadminputapi('order_status_remarkss', requestdata, token)
        if (!res.error) {
            setLoading(false)
            toast.success("Order Status Change Succesfully")
            setshow(false)
            handleGet()
            setallstatus(false)
        } else {
            setLoading(false)
        }
    }


    const itemOrderStatus = [
        'Pending',
        'Processing',
        'Accepted',
        'Shipped',
        'Delivered',
        'Completed',
        'Rejected'
    ];

    useEffect(() => {
        scrollToTop();
        handleGet();
    }, []);

    const handleGet = async () => {
        setLoading(true);
        try {
            const res = await superadmingetapi(`orders?sub_orders=${state.sub_orders._id}`, token);




            if (!res.error) {
                setData(res.data);
                setmisc(res.misc)
                setLoading(false)

            }
        } catch (error) {
            console.error("Error fetching order details:", error);
        } finally {
            setLoading(false);
        }
    };



    const stepsArray = [
        { title: "Pending", description: "Order is pending", completed: false },
        { title: "Processing", description: "Order is being processed", completed: false },
        { title: "Accepted", description: "Order has been accepted", completed: false },
        { title: "Shipped", description: "Order has been shipped", completed: false },
        { title: "Delivered", description: "Order has been delivered", completed: false },
        { title: "Completed", description: "Order is completed", completed: false },
        { title: "Rejected", description: "Order has been rejected", completed: false },
    ];

    const badgeStyles = {
        Pending: "bg-yellow-100 text-yellow-600",
        Delivered: "bg-green-100 text-green-600",
        Processing: "bg-blue-100 text-blue-600",
        Shipped: "bg-orange-100 text-orange-600",
        Completed: "bg-purple-100 text-purple-600",
        Accepted: "bg-teal-100 text-teal-600",
        Rejected: "bg-red-100 text-red-600",
    };

    // Determine the index of the current step based on item_order_status
    const getCurrentStepIndex = (status) => {
        return stepsArray.findIndex(step => step.title === status);
    };


    const content = (
        <form onSubmit={handlestatusorder}>
            <div className="p-4">
                <div className="mb-4 relative">
                    <label htmlFor="status" className="block text-gray-700 text-sm font-bold mb-2">
                        Order Status
                    </label>
                    <select
                        id="status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 pr-10"
                    >
                        <option value="">Select status</option>
                        {itemOrderStatus.map((status) => (
                            <option key={status} value={status}>
                                {status}
                            </option>
                        ))}
                    </select>
                    <div className="absolute inset-y-0 right-0 top-6 flex items-center px-2 pointer-events-none">
                        <i className="fa-solid fa-angle-down"></i>
                    </div>
                </div>
                <div>
                    <label htmlFor="remark" className="block text-gray-700 text-sm font-bold mb-2">
                        Remarks
                    </label>
                    <textarea
                        id="remark"
                        value={remark}
                        onChange={(e) => setRemark(e.target.value)}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        rows="4"
                    />
                </div>
                <button className='btn bg-blue-500 p-2 mt-3 rounded-md text-white'>Change Order Status</button>
            </div>
        </form>
    );




    return (
        <>
            {loading && <Loader />}
            {invoice ?
                <>
                    <button className='btn bg-blue-500 w-max px-5 py-2 rounded-2 text-white rounded-md' onClick={(e) => setinvoice(false)}>
                        <i className="fa-solid fa-arrow-left"></i> Back to Detail
                    </button>
                    <Invoice state={state} data={data} misc={misc} />
                </>
                : <section>
                    {show && <Modal onCancel={() => setshow(false)} content={content} />}
                    <div className="container mx-auto">
                        <div className="grid grid-cols-3">
                            <div className="col-span-3">
                                <TitleComp title="Order Detail" />
                            </div>

                        </div>
                        <div className="grid grid-cols-1 progressline">

                            <div className="grid grid-cols-3 gap-10 mt-5">
                                <div className="col-span-1">
                                    <div className="bg-white shadow rounded-lg">
                                        <div className="bg-stone-200 p-3 rounded-md">
                                            <h2 className="text-xl font-semibold">CUSTOMER & ORDER</h2>
                                        </div>
                                        <div className="grid grid-cols-2 p-3 gap-4 gap-y-4 mt-3">
                                            <div className="col-span-1">
                                                <h5 className='font-semibold'>Name</h5>
                                            </div>
                                            <div className="col-span-1">
                                                <p className='text-gray-600'>{state.user.name}</p>
                                            </div>
                                            <div className="col-span-1">
                                                <h5 className='font-semibold'>Phone</h5>
                                            </div>
                                            <div className="col-span-1">
                                                <p className='text-gray-600'>{state.user.phone}</p>
                                            </div>
                                            {/* <div className="col-span-1">
                                        <h5 className='font-semibold'>Product</h5>
                                    </div>
                                    <div className="col-span-1">
                                        <p className='text-gray-600'>{state.product_variant_id.name}</p>
                                    </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <div className="bg-white shadow rounded-lg">
                                        <div className="bg-stone-200 p-3 rounded-md">
                                            <h2 className="text-xl font-semibold">Vendor & Store</h2>
                                        </div>
                                        <div className="grid grid-cols-2 gap-4 gap-y-4 mt-3 p-3">
                                            <div className="col-span-1">
                                                <h5 className='font-semibold'>Name</h5>
                                            </div>
                                            <div className="col-span-1">
                                                <p className='text-gray-600'>{state.vendor.name}</p>
                                            </div>
                                            <div className="col-span-1">
                                                <h5 className='font-semibold'>Phone</h5>
                                            </div>
                                            <div className="col-span-1">
                                                <p className='text-gray-600'>{state.vendor.phone}</p>
                                            </div>
                                            <div className="col-span-1">
                                                <h5 className='font-semibold'>Store Name</h5>
                                            </div>
                                            <div className="col-span-1">
                                                <p className='text-gray-600'>{state.store.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-span-1">
                            <div className="bg-white shadow rounded-lg">
                                <div className="bg-stone-200 p-3 rounded-md">
                                    <h2 className="text-xl font-semibold">Change All Product Status</h2>
                                </div>
                                <div className="grid grid-cols-2 gap-4 gap-y-4 mt-3 p-3 items-center">
                                    <div className="col-span-1">
                                        <h5 className='font-semibold'>Status</h5>
                                    </div>
                                    <div className="col-span-1">
                                        <span className={`px-3  cursor-pointer relative bottom-1 py-1 rounded-full text-sm font-semibold bg-gray-500 text-white `} onClick={(e) => {

                                            setshow(true);
                                            setallstatus(true)
                                        }}>
                                            Select Status <i className="fa-solid fa-angle-down"></i>
                                        </span>

                                    </div>

                                </div>
                            </div>
                        </div> */}
                                <div className="col-span-1">
                                    <div className="text-end">
                                        <button onClick={(e) => setinvoice(true)} className='btn bg-orange text-white px-3 py-1 rounded-md '>
                                            <i className="fa-solid fa-file"></i> Invoice
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {data && data.map((item) => (

                            <>
                                <div className="col-span-2 mt-4">
                                    <Stepper
                                        steps={stepsArray.map((step) => ({
                                            title: step.title,
                                            description: step.description,
                                        }))}
                                        activeStep={getCurrentStepIndex(item.item_order_status)}
                                        completeColor="#1CAC78"
                                        activeColor="#1CAC78"
                                        defaultColor="gray"
                                        circleTop={true}
                                        size={40}
                                        circleFontSize={16}
                                        titleFontWeight={700}
                                        titleFontSize={16}
                                        completeTitleColor="#1CAC78"
                                        activeTitleColor="#1CAC78"
                                        defaultTitleColor="gray"
                                        lineMarginOffset={4}
                                        defaultBorderWidth={2}
                                        completeBarColor="#1CAC78"
                                        defaultBarColor="gray"
                                        barStyle="solid"
                                    />
                                </div>
                                <div key={item._id} className="grid grid-cols-2 bg-white shadow p-5 mt-3 rounded-lg gap-3">

                                    <div className="col-span-1">
                                        <div className="flex gap-10 items-center card" style={{ boxShadow: "none" }}>
                                            <div>
                                                <div className="image-container" style={{ height: "120px", width: "120px" }}>

                                                    <img
                                                        src={`${BASE_URL}${item.product.image.file}`}
                                                        alt={item.product.name}
                                                        loading='lazy'
                                                        style={{ height: "120px", width: "120px", objectFit: "cover", borderRadius: "10px" }}
                                                        onError={(e) => e.target.src = notfound}
                                                        className="w-full h-full object-cover"
                                                    />

                                                    <div className="price">  <del className='text-red-600'>₹{item.unit_mrp}</del> <span className='text-emerald-600'>₹{item.unit_selling_price}</span></div>
                                                </div>
                                                {/* <img
                                            src={`${BASE_URL}${item.product.image.file}`}
                                            className='rounded-2xl'
                                            style={{ height: "120px", width: "120px", objectFit: "cover" }}
                                            alt=""
                                            onError={(e) => e.target.src = notfound}
                                        />
                                        <div className="price  text-center mt-1">  <del className='text-red-600'>₹{item.unit_mrp}</del> <span className='text-emerald-600'>₹{item.unit_selling_price}</span></div> */}
                                            </div>
                                            <div className='relative'>
                                                <h3 className='text-3xl mb-2 font-semibold'><span className='me-4'>
                                                    Order #{item._id.substring(18, 24)}
                                                </span>
                                                    <span className={`px-3   relative bottom-1 py-1 rounded-full text-sm font-semibold ${badgeStyles[item.item_order_status] || "bg-gray-500 text-white"}`}
                                                    // onClick={(e) => {
                                                    //     setStatus(item.item_order_status)
                                                    //     setshow(true);
                                                    //     setorderid(item._id);
                                                    //     setRemark(item.shipping_remarks?.[item.shipping_remarks.length - 1]?.remark || '');
                                                    // }}
                                                    >
                                                        {item.item_order_status}
                                                        {/* <i className="fa-solid fa-angle-down"></i> */}
                                                    </span></h3>
                                                <div className="content">
                                                    {/* <div className="brand">{item.store.name}</div> */}
                                                    <div className="product-name mb-0" style={{ marginBottom: "5px" }}>{item.product.name}</div>

                                                    <div className="product-name">
                                                        <span className='text-emerald-400'>{item.qty} Items &#9864; <span className='text-[#ec5f1a] text-sm ms-3'>₹{item.selling_price}</span></span>
                                                    </div>



                                                </div>
                                                {/* <h5 className={`max-w-max ${badgeStyles[item.item_order_status]} px-3 py-1 rounded-md absolute top-1 right-[-90px]`}>
                                            <span>{item.item_order_status}</span>
                                        </h5> */}
                                                <div className="my-3">
                                                    <div className={`flex gap-6 ps-2 `}>

                                                        {item.user.name && <div className="uiverse">
                                                            <span className="tooltip1">User</span>
                                                            <span className='tooltipname'>
                                                                <i className="fa-solid fa-user"></i> {item.user.name}
                                                            </span>
                                                        </div>}

                                                        {item.vendor.name && <div className="uiverse">
                                                            <span className="tooltip1">Vendor</span>
                                                            <span className='tooltipname'>
                                                                <i className="fa-solid fa-shop"></i> {item.vendor.name}
                                                            </span>
                                                        </div>}
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-1">
                                        <div className="text-end mt-3 h-full">





                                            <div className="mt-3 flex justify-end ">
                                                <p className='bg-gray-100 px-3 py-1 rounded-sm'>
                                                    <span className='font-semibold'>Placed on</span> : {convertDateToMonthName(item.created_at)}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </>
                        ))}
                        {/* <div className="grid grid-cols-1">
                    <div className="col-span-1">
                        <TitleComp title="Sub Order" />
                    </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                    {data?.length > 0 && data?.map((item) => {
                        return (
                            <>
                                <div className="col-span-1">
                                    <div className="card">
                                        <div className="image-container">

                                            <img
                                                src={`${BASE_URL}${item.product.image.file}`}
                                                alt={item.product.name}
                                                loading='lazy'
                                                onError={(e) => e.target.src = notfound}
                                                className="w-full h-full object-cover"
                                            />

                                            <div className="price">  <del className='text-red-600'>₹{item.unit_mrp}</del> <span className='text-emerald-600'>₹{item.unit_selling_price}</span></div>
                                        </div>



                                        <div className="content">
                                            <div className="brand">{item.store.name}</div>
                                            <div className="product-name mb-0" style={{ marginBottom: "5px" }}>{item.product.name}</div>

                                            <div className="product-name">
                                                <span className='text-emerald-400'>{item.qty} Items &#9864; <span className='text-[#ec5f1a] text-sm ms-3'>₹{item.selling_price}</span></span>
                                            </div>



                                        </div>

                                        <div className="mb-3">
                                            <div className={`flex gap-6 ps-2 `}>

                                                {item.user.name && <div className="uiverse">
                                                    <span className="tooltip1">User</span>
                                                    <span className='tooltipname'>
                                                        <i className="fa-solid fa-user"></i> {item.user.name}
                                                    </span>
                                                </div>}

                                                {item.vendor.name && <div className="uiverse">
                                                    <span className="tooltip1">Vendor</span>
                                                    <span className='tooltipname'>
                                                        <i className="fa-solid fa-shop"></i> {item.vendor.name}
                                                    </span>
                                                </div>}
                                            </div>
                                        </div>




                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div> */}
                    </div>
                </section >}
        </>
    );
}

export default OrderDetail;

