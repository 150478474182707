import { useEffect, useState } from "react";
import convertDateToMonthName from "../component/FormDate";
import { masterdeleteapi, mastergetapi, masterpostapi, masterputapi } from "../Api/Api";
import { toast } from "react-toastify";
import Loader from "../component/Loader";
import TitleComp from "../component/TitleComp";
import TableComp from "../component/TableComp";
import { hasPermission } from "../Access/Permission";


function Faq() {
    let token = localStorage.getItem("token");
    const [data, setdata] = useState([])
    const [loading, setloading] = useState(false)
    const [editid, seteditid] = useState("")

    const headers = {
        Authorization: `Bearer ${token}`
    };

    const [formData, setFormData] = useState({
        question: '',
        answer: '',

    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };



    const getRowId = (row) => row._id
    const columns = [
        {
            field: "question",
            headerName: "Question",
            flex: 1,
        },
        {
            field: "answer",
            headerName: "Answer",
            flex: 1,
        },

        {
            field: "created_at",
            headerName: "Created Date",
            flex: 1,
            renderCell: (params) => { // Corrected renderCell casing
                return <span>{convertDateToMonthName(params.row.created_at)}</span>;
            },
        },



        ...(hasPermission(`FAQ`, 'Update') ? [
            {
                field: "edit",
                headerName: "Edit",
                flex: 1,
                renderCell: (params) => { // Corrected renderCell casing
                    return <button className='cutombtn' onClick={(e) => handleEdit(e, params.row)} ><i className="fa-solid fa-pen-to-square"></i></button>;
                },
            },
        ] : []),
        ...(hasPermission(`FAQ`, 'Delete') ? [
            {
                field: "delete",
                headerName: "Delete",
                flex: 1,
                renderCell: (params) => { // Corrected renderCell casing
                    return <button className='cutombtn' onClick={(e) => handledelete(e, params.row._id)}><i className="fa-solid fa-trash"></i></button>;
                },
            },
        ] : []),


    ];


    const handleEdit = (e, item) => {
        e.preventDefault();
        seteditid(item._id)
        setFormData({
            question: item.question,
            answer: item.answer,
        })
    };

    const handleget = async () => {
        let res = await mastergetapi('faqs', token)

        if (!res.error) {
            setdata(res.data)
        }
    }

    useEffect(() => {
        handleget()
    }, [])

    const handledelete = async (e, id) => {
        const todayDate = new Date().toISOString();

        let requestdata = {
            data: {
                _id: id
            }
        }
        let res = await masterdeleteapi(`faqs`, requestdata, token);

        if (!res.error) {
            toast.success("Faq deleted successfully");
            handleget();
        } else {
            toast.error("Failed to delete Faq. Please try again.");
        }
    };






    const handleSubmit = async (e) => {
        setloading(true);
        e.preventDefault();



        let res = "";
        if (editid) {
            formData._id = editid;



            res = await masterputapi("faqs", formData, token);
        } else {

            res = await masterpostapi("faqs", formData, token);
        }


        if (!res.data.error) {
            setloading(false);
            handleget();
            setFormData({
                question: '',
                answer: '',

            })
            toast.success(`${editid ? "Data Updated Successfully" : "Data Submitted Successfully"}`);

            seteditid("");
        } else {
            setloading(false)
            toast.error(`${editid ? "Data Not Updated Successfully" : "Data Not Submitted Successfully"}`)
        }
    };

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setFormData((prevData) => ({
            ...prevData,
            description: data,
        }));
    };

    return (
        <>
            {loading && <Loader />}
            <section>
                <div className="container">
                    <form action="" onSubmit={handleSubmit}>
                        <div className="grid grid-cols-3 gap-3">
                            <div className="col-span-3">
                                <TitleComp title="FAQ" />
                            </div>
                            {hasPermission('FAQ', 'Write') && <>
                                <div className="col-span-1">
                                    <label htmlFor="title">Title</label>
                                    <input
                                        type="text"
                                        id="question"
                                        name="question"
                                        value={formData.question}
                                        onChange={handleChange}
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    />
                                </div>

                                <div className="col-span-1">
                                    <label htmlFor="answer">Answer</label>
                                    <input
                                        type="text"
                                        id="answer"
                                        name="answer"
                                        value={formData.answer}
                                        onChange={handleChange}
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    />
                                </div>



                                <div className="col-span-3">
                                    <button className="btn bg-blue-500 text-white px-6 rounded-md py-3">
                                        Submit
                                    </button>
                                </div>
                            </>}
                        </div>
                    </form>
                </div>
            </section>

            <section>
                <div className="container">
                    <div className="grid grid-cols-1">

                        <div className="cols-span-1">
                            <TableComp data={data} columns={columns} getRowId={getRowId} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Faq;
