import React from 'react'
import noperm from "../assets/images/notpermission.jpg"

function NotPermission(props) {
    return (
        <>
            <div className="container">
                <div className="grid grid-cols-1">
                    <div className="flex justify-center mt-24">
                        <div className="">
                            <img src={noperm} alt="" className='h-[400px] rounded-xl' />

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotPermission
