import React, { useEffect, useState } from 'react'
import TableComp from '../../component/TableComp'
import { mastergetapi, masterputapi } from '../../Api/Api'
import TitleComp from '../../component/TitleComp'
import convertDateToMonthName from '../../component/FormDate'
import { useNavigate, useParams } from 'react-router-dom'

import { toast } from 'react-toastify'
import Loader from '../../component/Loader'
import { hasPermission } from '../../Access/Permission'
import { BASE_URL } from '../../Api/BaseUrl'

function ProductCategory() {
    const [data, setdata] = useState([])
    const { id } = useParams()
    let token = localStorage.getItem("token")
    const navigate = useNavigate()
    const [loading, setloading] = useState(false)

    const handlePermission = async () => {
        setloading(true)
        let res = await mastergetapi(`product_category?connected_app_category=${id}`, token)
        if (!res.error) {
            setdata(res.data)
            setloading(false)
        } else {
            setloading(false)
        }

    }

    useEffect(() => {
        handlePermission()
    }, [])
    const columns = [
        {
            field: "image",
            headerName: "Image",
            flex: 1,
            renderCell: (params) => {

                return <img src={`${BASE_URL}${params.row.image?.file}`} style={{ height: "50px", width: "50px", borderRadius: "50%" }} className='img-fluid' alt="" />
            }
        },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
        },
        {
            field: "created_at",
            headerName: "Created Date",
            flex: 1,
            renderCell: (params) => { // Corrected renderCell casing
                return <span>{convertDateToMonthName(params.row.created_at)}</span>;
            },
        },




        ...(hasPermission(`AppCategory`, 'Update') ? [{
            field: "edit",
            headerName: "Edit",
            flex: 1,
            renderCell: (params) => { // Corrected renderCell casing
                return <button className='cutombtn' onClick={(e) => handleNavigation(params.row)}><i className="fa-solid fa-pen-to-square"></i></button>;
            },
        }] : []),

        ...(hasPermission(`AppCategory`, 'Delete') ? [{
            field: "delete",
            headerName: "Delete",
            flex: 1,
            renderCell: (params) => { // Corrected renderCell casing

                return <button className='cutombtn' onClick={(e) => handledelete(e, params.row)}><i className="fa-solid fa-trash"></i></button>;
            },
        }] : []),



    ];

    const handledelete = async (e, item) => {
        e.preventDefault()
        setloading(true)
        let requestdata = {
            _id: item._id,
            deleted_at: "1"
        }
        let res = await masterputapi("app_category", requestdata, token)
        if (!res.error) {

            setloading(false)
            toast.success("Data deleted Succesfully")
        } else {
            setloading(false)
        }

    }

    let title = "Child App Category"
    const formattedTitle = title.replace(/\s+/g, '_');

    const handleNavigation = (item) => {
        // Replace spaces with underscores in the URL and title


        // Navigate to the desired path with the formatted URL and title
        navigate(`/addproductcategory/${id}`, { state: item });
    };

    const getRowId = (row) => row._id
    return (
        <>
            {loading && <Loader />}
            <section>
                <div className="container">
                    <div className="grid grid-cols-1">
                        <div className="cols-span-1">
                            <TitleComp title={"Product Category"} />
                        </div>
                        {hasPermission('AppCategory', 'Write') && <div className="cols-span-1">
                            <div className="text-end mb-2">
                                <button className='btn bg-blue-500 px-5 py-2 rounded-md text-white' onClick={(e) => navigate(`/addproductcategory/${id}`)}>Add Product Category</button>
                            </div>
                        </div>}
                        <div className="cols-span-1">
                            <TableComp data={data} columns={columns} getRowId={getRowId} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default ProductCategory
