import React, { useEffect, useState } from 'react'
import { mastergetapi, superadminputapi } from '../../Api/Api'
import TitleComp from '../../component/TitleComp'
import ProductComp from '../../component/ProductComp'
import Loader from '../../component/Loader'
import { useParams } from 'react-router-dom'
import ProductVariantComp from '../../component/ProductVariantComp'

function MasterProductVar() {
    const { id } = useParams()
    let token = localStorage.getItem("token")
    const [data, setdata] = useState([])
    const [loading, setloading] = useState(false)

    const handleget = async () => {
        let res = await mastergetapi(`fetch_master_product_variants?_id=${id}`, token)

        setdata(res.data)
    }

    useEffect(() => {
        handleget()
    }, [])

    const handleCheckboxChange = async (itemId, status) => {
        setloading(true);
        let requestdata = {
            _id: itemId,
            is_disabled: !status
        }
        let res = await superadminputapi(`fetch_master_products`, requestdata, token);

        if (!res.error) {
            setloading(false)
            handleget()
        }
        setloading(false);
    };
    return (
        <>
            {loading && <Loader />}
            <section>
                <div className="container">
                    <div className="grid grid-cols-3 gap-10">
                        <div className="col-span-3">
                            <TitleComp title="Master Products Variant" />
                        </div>

                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-6">

                        <ProductVariantComp data={data} handleCheckboxChange={handleCheckboxChange} />
                    </div>
                </div>
            </section>
        </>
    )
}

export default MasterProductVar
