import React from 'react'
import GetPage from '../component/GetPage'

function Position() {
    return (
        <>
            <GetPage title="Position" url="positions" roletitle="Positions" value="Write" />
        </>
    )
}

export default Position
