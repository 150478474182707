import React, { useEffect } from 'react'
import { superadmingetapi } from '../../Api/Api'

function ProfilePage() {
    let token = localStorage.getItem("token")

    
    return (
        <div>
            Profile
        </div>
    )
}

export default ProfilePage
