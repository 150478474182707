


import React, { useEffect, useState } from 'react'
import { superadmingetapi, superadminputapi } from '../../Api/Api'
import notfound from "../../assets/images/notfound.avif"
import Loader from '../../component/Loader'
import { BASE_URL } from '../../Api/BaseUrl'
import { useNavigate, useParams } from 'react-router-dom'
import approve from "../../assets/images/approved.png"
import reject from "../../assets/images/rejected.png"
import store from "../../assets/images/store.jpg"
import convertDateToMonthName from '../../component/FormDate'
import Notfound from '../../component/Notfound'
import { base64ToImageSrc } from '../../component/ImageConverter'
import TitleComp from '../../component/TitleComp'
import Modal from '../../component/Modal'
import { toast } from 'react-toastify'
import axios from 'axios'

function OrderHistory() {
    const navigate = useNavigate()
    let token = localStorage.getItem("token")
    const { id } = useParams()
    const [rangeshow, setrangeshow] = useState(false)
    const [mainid, setmainid] = useState("")
    const [range, setrange] = useState("")
    const [loading, setloading] = useState(false)
    const [popup, setpopup] = useState(false)
    const [popupimg, setpopupimg] = useState('')
    const [data, setdata] = useState([])
    const [documentdata, setdocumentdata] = useState([])
    const [misc, setmisc] = useState([])
    const [datanotfound, setdatanotfound] = useState(false)


    const [location, setLocation] = useState({
        lat: 28.6139, // default latitude (Delhi)
        lng: 77.2090, // default longitude (Delhi)
    });

    // Construct the Google Maps embed URL dynamically
    const mapSrc = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d448196.5263247024!2d${location.lng}!3d${location.lat}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd5b347eb62d%3A0x37205b715389640!2sDelhi!5e0!3m2!1sen!2sin!4v1728973100335!5m2!1sen!2sin`;

    // Function to update location (you can replace this with API or props data)
    const updateLocation = (lat, lng) => {
        setLocation({ lat, lng });
    };

    useEffect(() => {
        if (data) {
            updateLocation(data.lat, data.long)
        }
    }, [data])

    const handleget = async () => {
        setloading(true);
        try {
            let res = await superadmingetapi(`fetch_store?vendor=${id}`, token);

            setdata(res.data?.[0]);
            setmisc(res.misc)
            if (res.data.error) {
                setdatanotfound(true)
            }

        } catch (error) {
            console.error('Error fetching orders:', error);

        } finally {
            setloading(false);
        }
    };

    useEffect(() => {
        handleget()
    }, [])


    const handledocument = async () => {
        let res = await superadmingetapi(`stores_documents?store=${data?._id}`, token)

        setdocumentdata(res?.data?.data?.[0]?.data)
    }

    useEffect(() => {
        if (data?._id) {
            handledocument()
        }
    }, [data])

    const handleCheckboxChange = async (itemId, status) => {
        setloading(true);
        let requestdata = {
            _id: itemId,
            verified: !status
        }
        let res = await superadminputapi(`update_store`, requestdata, token);

        if (!res.error) {
            setloading(false)
            handleget()
        }
        setloading(false);
    };

    const handleCheckboxSelfChange = async (itemId, status) => {
        setmainid(itemId)
        setrangeshow(!status)
        console.log(!status)
        if (!status) {
            if (rangeshow) {

                if (range == "") {
                    toast.error("Please fill the range")
                } else {
                    setloading(true);
                    let requestdata = {
                        _id: itemId ?? mainid,
                        self_delivery: !status,
                        radius: range
                    }
                    let res = await superadminputapi(`update_store`, requestdata, token);

                    if (!res.error) {
                        setloading(false)
                        handleget()
                        setrangeshow(false)
                        setrange("")
                    }
                    setloading(false);
                }
            }
        } else {
            setloading(true);
            let requestdata = {
                _id: itemId ?? mainid,
                self_delivery: !status,
                radius: "9999"
            }
            let res = await superadminputapi(`update_store`, requestdata, token);

            if (!res.error) {
                setloading(false)
                handleget()
                setrangeshow(false)
                setrange("")
            }
            setloading(false);
        }

    };

    const handlerangesubmit = (e) => {
        e.preventDefault()
        handleCheckboxSelfChange(mainid)
    }


    const handlepop = (e, item) => {
        e.preventDefault()
        setpopupimg(base64ToImageSrc(item?.Image?.file))
        setpopup(true)

    }
    const content = (
        <>
            <img className='w-full h-[400px] object-contain rounded-lg' src={popupimg} alt="" />

        </>
    )

    const handleremovecat = async (e, appid, storeid) => {
        setloading(true)
        let requestdata = {
            _id: storeid,
            connected_app_category: appid
        }
        let res = await superadminputapi(`update_store_remove_app_category`, requestdata, token)

        if (!res.error) {
            setloading(false)
            handleget()
            setrangeshow(false)
            setrange("")
        } else {
            setloading(false)
        }
    }

    return (
        <>
            {loading && <Loader />}
            <section className=''>
                <img
                    className="w-full h-[350px] object-cover rounded-b-3xl shadow-lg"
                    src={store}
                    alt="Shop with We Are Open sign"
                />
            </section>

            {!datanotfound ?
                <>
                    <section className='px-8  relative top-[-100px]'>
                        <div className="container mx-auto">
                            <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
                                <div className="col-span-4 ">
                                    <div className="bg-white shadow-lg rounded-2xl p-6">
                                        <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
                                            <div className="col-span-1">
                                                <img
                                                    className='rounded-xl shadow-md w-full '
                                                    style={{ height: "300px", objectFit: "cover" }}
                                                    src={`${BASE_URL}${data?.image?.file}`}
                                                    onError={(e) => e.target.src = notfound}
                                                    alt=""
                                                />
                                                {/* <iframe
                                                    title="google-map"
                                                    className='w-100 mt-5 rounded-4'
                                                    height="200"
                                                    style={{ border: 0, marginTop: "20px" }}
                                                    loading="lazy"
                                                    allowFullScreen
                                                    referrerPolicy="no-referrer-when-downgrade"
                                                    src={mapSrc}
                                                ></iframe> */}
                                            </div>
                                            <div className="col-span-2">
                                                <div className="p-3 storebox">
                                                    <div className="flex items-center gap-4">
                                                        <h3 className='text-4xl text-orange-600 font-bold'>{data?.name}</h3>
                                                        <label htmlFor="" className="bg-white">
                                                            <div className="checkbox-wrapper-19">
                                                                <input
                                                                    id={`cbtest-19-${data?._id}`}
                                                                    type="checkbox"
                                                                    checked={data?.verified}
                                                                    onChange={() => handleCheckboxChange(data?._id, data?.verified)}
                                                                />
                                                                <label className="check-box" htmlFor={`cbtest-19-${data?._id}`}></label>
                                                            </div>
                                                        </label>
                                                    </div>

                                                    <div className="flex flex-wrap gap-6 items-center mt-4">
                                                        <h5 className='text-gray-500'>Self Delivery:</h5>
                                                        {/* <img src={data?.self_delivery ? approve : reject} className='h-[40px]' alt="" /> */}
                                                        <label htmlFor="" className="bg-white">
                                                            <div className="checkbox-wrapper-19">
                                                                <input
                                                                    id={`cbtest-18-${data?._id}`}
                                                                    type="checkbox"
                                                                    checked={data?.self_delivery}
                                                                    onChange={() => handleCheckboxSelfChange(data?._id, data?.self_delivery)}
                                                                />
                                                                <label className="check-box" htmlFor={`cbtest-18-${data?._id}`}></label>
                                                            </div>
                                                        </label>

                                                    </div>

                                                    {rangeshow && <div className="">
                                                        <form onSubmit={handlerangesubmit} className='flex flex-wrap gap-2 items-center mt-4'>
                                                            <h5 className='text-gray-500'>Fill Radius:</h5>
                                                            <input
                                                                type="text"
                                                                value={range}
                                                                onChange={(e) => setrange(e.target.value)}
                                                                className="border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300 ease-in-out"
                                                            />


                                                        </form>
                                                        <div className='text-sm text-red-500' style={{ paddingLeft: "5.4rem" }}> <p> <i class="fa-solid fa-circle-info me-2 mt-2"></i>Press Enter to submit</p></div>
                                                    </div>}

                                                    <div className="flex flex-wrap gap-2 items-center mt-4">
                                                        <h5 className='text-gray-500'>Address:</h5>
                                                        <span className="text-lg text-gray-700">{data?.city}, {data?.state}, {data?.pincode}, {data?.address}</span>
                                                    </div>

                                                    <div className="flex flex-wrap gap-2 items-center mt-4">
                                                        <h5 className='text-gray-500'>Connected App Category:</h5>
                                                        <div className="flex flex-wrap gap-2">
                                                            {data?.connected_app_category?.map((item) => (
                                                                <>
                                                                    <span className="text-blue-500">
                                                                        <i className="fa-solid fa-circle"></i>
                                                                    </span>  <h6 className='font-bold text-blue-500'>{item?.name} <i class="fa-solid fa-xmark " style={{ cursor: "pointer" }} onClick={(e) => handleremovecat(e, item._id, data._id)}></i></h6>
                                                                </>
                                                            ))}
                                                        </div>
                                                    </div>

                                                    <div className="flex flex-wrap gap-2 items-center mt-4">
                                                        <h5 className='text-gray-500'>Minimum Order Value:</h5>
                                                        <h6 className="text-lg text-gray-700">₹ {data?.minimum_order_value}</h6>
                                                    </div>

                                                    {data.self_delivery && <div className="flex flex-wrap gap-2 items-center mt-4">
                                                        <h5 className='text-gray-500'>Radius:</h5>
                                                        <h6 className="text-lg text-gray-700">{data?.radius} km</h6>
                                                    </div>}

                                                    <div className="flex flex-wrap gap-10 w-full items-center mt-4">
                                                        <div className="">
                                                            <h5 className='text-gray-500'>Start Time:</h5>
                                                            <h6 className="text-lg text-gray-700">{data?.start_time}</h6>
                                                        </div>
                                                        <div className="">
                                                            <h5 className='text-gray-500'>Close Time:</h5>
                                                            <h6 className="text-lg text-gray-700">{data?.close_time}</h6>
                                                        </div>
                                                    </div>



                                                    <div className="flex flex-wrap gap-2 items-center mt-4">
                                                        <h5 className='text-gray-500'>Subscription:</h5>
                                                        <img src={misc?.subscription ? approve : reject} className='h-[40px]' alt="" />
                                                    </div>

                                                    <div className="flex flex-wrap gap-2 items-center mt-4">
                                                        <h5 className='text-gray-500'>Vendor Phone:</h5>
                                                        <h6 className="text-lg text-gray-700">{data?.user?.phone}</h6>
                                                    </div>

                                                    <p className='bg-gray-100 w-max px-4 py-2 mt-4 rounded-lg shadow-sm'>
                                                        <span className='font-semibold'>Store Created on:</span> {convertDateToMonthName(data?.created_at)}
                                                    </p>

                                                    <div className="mt-6">
                                                        <div className={`flex gap-6`}>
                                                            {data?.user?.name && (
                                                                <div className="uiverse">
                                                                    <span className="tooltip1">User</span>
                                                                    <span className='tooltipname'>
                                                                        <i className="fa-solid fa-user"></i> {data?.user?.name}
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </>
                :
                <>
                    <div className=" storeboxnotfound px-8 relative top-[-50px]">
                        <Notfound title="Store Not Found" />
                    </div>
                </>
            }
            <section className='relative px-8 top-[-90px]'>
                <div className="container ">
                    <div className="grid grid-cols-3 gap-8">
                        <div className="col-span-3">
                            <TitleComp title="Store Documents" />

                        </div>
                        <div className="col-span-3">
                            <p className='text-red-400 text-end'>
                                <i className="fa-solid fa-circle-info"></i> You can enlarge the photo by clicking on it.
                            </p>

                        </div>
                        {documentdata?.map((item) => {
                            return (
                                <>
                                    {/* <img src={item.Image?.file} alt="" /> */}

                                    <div className="col-span-1">
                                        <div className="bg-white shadow p-3 text-center rounded-lg">
                                            <img className='w-full bg-white shadow p-3  h-[200px] object-contain rounded-lg' onClick={(e) => handlepop(e, item)} src={base64ToImageSrc(item?.Image?.file)} onError={(e) => (e.target.src = notfound)} alt="" />


                                            <div className="p-4  rounded-lg ">
                                                <div className="flex justify-between py-3 border-b border-gray-300">
                                                    <h3 className="font-semibold text-gray-700 text-sm">Document Name</h3>
                                                    <h3 className="text-gray-600">{item.document_name}</h3>
                                                </div>
                                                <div className="flex justify-between py-3 border-b border-gray-300">
                                                    <h3 className="font-semibold text-gray-700 text-sm">Document Number</h3>
                                                    <h3 className="text-gray-600">{item.Number}</h3>
                                                </div>
                                                {item.Name === "Gst" && (
                                                    <div className="flex justify-between py-3">
                                                        <h3 className="font-semibold text-gray-700 text-sm">Establish Date</h3>
                                                        <h3 className="text-gray-600">{item.EstbDate}</h3>
                                                    </div>
                                                )}
                                            </div>



                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
            </section>
            {popup && <Modal onCancel={() => setpopup(false)} content={content} title={"Document Image"} />}
        </>
    )
}

export default OrderHistory
