import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { superadmingetapi, superadminputapi } from '../../Api/Api';
import Notfound from '../../component/Notfound';
import Loader from '../../component/Loader';
import TitleComp from '../../component/TitleComp';
import { BASE_URL } from '../../Api/BaseUrl';
import imagenot from "../../assets/images/notfound.avif";
import veg from "../../assets/images/veg.png";
import nonveg from "../../assets/images/nonveg.jpg";
import convertDateToMonthName from '../../component/FormDate';
import ProductComp from '../../component/ProductComp';
import ProductVariantComp from '../../component/ProductVariantComp';

const truncateText = (text, wordLimit) => {
    const words = text?.split(' ');
    if (words.length > wordLimit) {
        return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
};

function ProductVariantList() {
    const { id } = useParams();
    let token = localStorage.getItem("token");
    const [loading, setloading] = useState(false);
    const [search, setsearch] = useState("")
    const [filterdata, setfilterdata] = useState([])
    const [data, setdata] = useState([]);

    let handleget = async () => {
        setloading(true);
        let res = await superadmingetapi(`product_variant?product=${id}`, token);

        if (!res.error) {
            setdata(res.data);
        }
        setloading(false);
    };

    useEffect(() => {
        handleget();
    }, []);

    const handleCheckboxChange = async (itemId, status) => {
        setloading(true);
        let requestdata = {
            _id: itemId,
            is_disabled: !status
        }
        let res = await superadminputapi(`product_variant`, requestdata, token);

        if (!res.error) {
            setloading(false)
            handleget()
        }
        setloading(false);
    };

    const handleFilter = (e) => {
        e.preventDefault();
        const filteredData = data.filter((item) =>
            item?.name.toLowerCase().includes(search.toLowerCase())
        );

        setfilterdata(filteredData);
    };


    useEffect(() => {
        if (data) {
            setfilterdata(data)
        }
    }, [data])

    return (
        <>
            {loading && <Loader />}
            <div className="grid grid-cols-3 gap-5">
                <div className="col-span-3">
                    <TitleComp title="Product Variant List" />
                </div>
                <div className="col-span-1">
                    <form onSubmit={handleFilter}>
                        <div className="searchbar">
                            <div className="InputContainer">
                                <input type="text" value={search} onChange={(e) => setsearch(e.target.value)} name="text" id="input" placeholder="Search" />
                                <div className="border"></div>

                                <button className="micButton"><svg viewBox="0 0 512 512" className="searchIcon"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"></path></svg>
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-span-1">
                    <button className='btn bg-blue-500 text-sm px-5 py-2 hover:bg-blue-600 hover:shadow-sm text-white rounded-md w-max-w' onClick={(e) => { setfilterdata(data); setsearch("") }}>Clear All</button>
                </div>
            </div>
            {filterdata.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-6">

                    <ProductVariantComp data={filterdata} handleCheckboxChange={handleCheckboxChange} />
                </div >
            ) : (
                <div className="grid grid-cols-3 justify-center">
                    <div className="col-span-1"></div>
                    <div className="col-span-1">
                        <Notfound title="Product Variant Not Found" />
                    </div>
                    <div className="col-span-1"></div>
                </div>
            )
            }
        </>
    );
}

export default ProductVariantList;
