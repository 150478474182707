import React, { useEffect, useState } from 'react';
import TitleComp from '../../component/TitleComp';
import { mastergetapi, masterpostapi, masterputapi } from '../../Api/Api';
import TableComp from '../../component/TableComp';
import convertDateToMonthName from '../../component/FormDate';
import Loader from '../../component/Loader';
import { toast } from 'react-toastify';
import { Checkbox } from '@mui/material';
import { hasPermission } from '../../Access/Permission';

function AddSubscription() {
  let token = localStorage.getItem("token");
  const [loading, setloading] = useState(false)
  const [edit, setedit] = useState("")
  const [data, setdata] = useState([])
  const getRowId = (row) => row._id
  const [formData, setFormData] = useState({
    name: '',
    short_description: '',
    description: '',
    price: '',
    time_period_count: '',
    time_period: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    setloading(true)
    e.preventDefault();
    let res = ""
    if (edit) {
      formData._id = edit
      res = await masterputapi('subscriptions', formData, token)

    } else {
      res = await masterpostapi('subscriptions', formData, token)
    }

    if (!res.error) {
      setloading(false)
      handlesubs();
      toast.success(`${edit ? "Data Updated Successfully" : "Data Submitted Successfully"}`);
      setFormData({
        name: '',
        short_description: '',
        description: '',
        price: '',
        time_period_count: '',
        time_period: '',
      })
      setedit("")
    }
  };



  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
    },
    {
      field: "periodcount",
      headerName: "Period Count",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <span>{params.row.time_period_count} {params.row.time_period}</span>
          </>
        )
      }
    },
    {
      field: "short_description",
      headerName: "Short Description",
      flex: 1,
    },
    {
      field: "created_at",
      headerName: "Created Date",
      flex: 1,
      renderCell: (params) => { // Corrected renderCell casing
        return <span>{convertDateToMonthName(params.row.created_at)}</span>;
      },
    },



    ...(hasPermission(`Subscription`, 'Update') ? [{
      field: "edit",
      headerName: "Edit",
      flex: 1,
      renderCell: (params) => { // Corrected renderCell casing
        return <button className='cutombtn' onClick={(e) => handleEdit(e, params.row)}><i className="fa-solid fa-pen-to-square"></i></button>;
      },
    }] : []),

    ...(hasPermission(`Subscription`, 'Delete') ? [{
      field: "delete",
      headerName: "Delete",
      flex: 1,
      renderCell: (params) => { // Corrected renderCell casing

        const ischecked = params.row.deleted_at ? true : false

        return <button className='cutombtn ' style={{ padding: "0" }} onClick={(e) => handledelete(e, params.row._id, params.row.deleted_at)}><Checkbox checked={ischecked} className='p-0' /></button>;
      },
    }] : []),

  ];

  const handlesubs = async () => {
    let res = await mastergetapi('subscriptions', token)

    setdata(res.data)
  }

  const handleEdit = (e, item) => {
    setedit(item._id)
    setFormData({
      name: item.name,
      short_description: item.short_description,
      description: item.description,
      price: item.price,
      time_period_count: item.time_period_count,
      time_period: item.time_period,
    })
  }


  const handledelete = async (e, id, deleted) => {
    const todayDate = new Date().toISOString(); // Get today's date in ISO format

    let requestdata = {
      _id: id,
      deleted_at: deleted ? "" : todayDate,
    };

    let res = await masterputapi('subscriptions', requestdata, token);

    if (!res.error) {
      toast.success("Subscription deleted successfully");
      handlesubs(); // Refresh the data after deletion
    } else {
      toast.error("Failed to delete subscription. Please try again.");
    }
  };


  useEffect(() => {
    handlesubs()
  }, [])

  return (
    <>
      {loading && <Loader />}
      {hasPermission('Subscription', 'Write'
      ) && <section className="pb-8">
          <div className="container mx-auto">
            <div className="grid grid-cols-3 ">
              <div className="col-span-3">
                <TitleComp title={!edit ? `Add Subscription` : "Edit Subscription"} />
              </div>
              <div className="col-span-3">
                <form onSubmit={handleSubmit}>
                  <div className="grid grid-cols-3 gap-4">
                    <div className="col-span-1">
                      <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                        Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        required
                      />
                    </div>

                    <div className="col-span-1">
                      <label htmlFor="short_description" className="block text-sm font-medium text-gray-700">
                        Short Description
                      </label>
                      <input
                        type="text"
                        name="short_description"
                        id="short_description"
                        value={formData.short_description}
                        onChange={handleChange}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        required
                      />
                    </div>



                    <div className="col-span-1">
                      <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                        Price
                      </label>
                      <input
                        type="number"
                        name="price"
                        id="price"
                        value={formData.price}
                        onChange={handleChange}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        required
                      />
                    </div>

                    <div className="col-span-1">
                      <label htmlFor="time_period_count" className="block text-sm font-medium text-gray-700">
                        Time Period Count
                      </label>
                      <input
                        type="number"
                        name="time_period_count"
                        id="time_period_count"
                        value={formData.time_period_count}
                        onChange={handleChange}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        required
                      />
                    </div>

                    <div className="col-span-1">
                      <label htmlFor="time_period" className="block text-sm font-medium text-gray-700">
                        Time Period (e.g., days, weeks, months)
                      </label>
                      <input
                        type="text"
                        name="time_period"
                        id="time_period"
                        value={formData.time_period}
                        onChange={handleChange}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        required
                      />
                    </div>


                    <div className="col-span-3">
                      <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                        Description
                      </label>
                      <textarea
                        name="description"
                        id="description"
                        value={formData.description}
                        onChange={handleChange}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        rows="3"
                        required
                      />
                    </div>
                    <div className="col-span-1">
                      <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
                      >
                        {edit ? "Update" : "Add"} Subscription
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>}
      <section>
        <div className="container">
          <div className="grid grid-cols-1">
            <div className="col-span-1">
              <TitleComp title={" Subscription List"} />
            </div>
            <div className="cols-span-1">
              <TableComp data={data} columns={columns} getRowId={getRowId} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AddSubscription;




