import React, { useEffect, useState } from 'react'




import { useLocation, useNavigate } from 'react-router-dom'

import { toast } from 'react-toastify'
import { mastergetapi, masterputapi, superadmingetapi } from '../../Api/Api'
import convertDateToMonthName from '../../component/FormDate'
import Loader from '../../component/Loader'
import ConfirmPopup from '../../component/ConfirmPopup'
import TitleComp from '../../component/TitleComp'
import TableComp from '../../component/TableComp'
import { hasPermission } from '../../Access/Permission'


function CouponList(props) {

    const [data, setdata] = useState([])
    const [delid, setdelid] = useState("")
    let token = localStorage.getItem("token")
    const navigate = useNavigate()
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [loading, setloading] = useState(false)

    const handlePermission = async () => {
        setloading(true)
        let res = await superadmingetapi("coupons", token)

        if (!res.error) {
            setdata(res.data)
            setloading(false)
        } else {
            setloading(false)
        }

    }




    useEffect(() => {
        handlePermission()
    }, [])
    const columns = [
        {
            field: "name",
            headerName: "Name",
            flex: 1,
        },
        {
            field: "off_value",
            headerName: "Value",
            flex: 1,
            renderCell: (params) => {
                return `${params.row.off_value} ${params.row.off_type == "Percent" ? "%" : ""}`
            }
        },
        {
            field: "max_off",
            headerName: "Max Off",
            flex: 1,
        },
        {
            field: "frequency",
            headerName: "Frequency",
            flex: 1,
        },
        {
            field: "start_at",
            headerName: "Start Date",
            flex: 1,
            renderCell: (params) => { // Corrected renderCell casing
                return <span>{convertDateToMonthName(params.row.start_date)}</span>;
            },
        },
        {
            field: "end_date",
            headerName: "End Date",
            flex: 1,
            renderCell: (params) => { // Corrected renderCell casing
                return <span>{convertDateToMonthName(params.row.end_date)}</span>;
            },
        },

        ...(hasPermission(`Coupons`, 'Update') ? [{
            field: "edit",
            headerName: "Edit",
            flex: 1,
            renderCell: (params) => { // Corrected renderCell casing
                return <button className='cutombtn' onClick={(e) => handleNavigation(params.row)}><i className="fa-solid fa-pen-to-square"></i></button>;
            },
        }] : []),
        ...(hasPermission(`Coupons`, 'Delete') ? [{
            field: "delete",
            headerName: "Delete",
            flex: 1,
            renderCell: (params) => { // Corrected renderCell casing
                return <button className='cutombtn' onClick={(e) => showDeleteConfirmation(params.row)}><i className="fa-solid fa-trash"></i></button>;
            },
        }] : []),




    ];

    const showDeleteConfirmation = (item) => {
        setdelid(item._id);
        setConfirmDelete(true);
    }

    const handledelete = async (item) => {

        setloading(true)
        let requestdata = {
            _id: delid,
            deleted_at: "1"
        }
        let res = await masterputapi("coupons", requestdata, token)
        if (!res.error) {

            setloading(false)
            toast.success("Data deleted Succesfully")
        } else {
            setloading(false)
        }

    }


    const handleDeleteConfirmed = async () => {
        handledelete()
        setConfirmDelete(false)
    }

    const handleNavigation = (item) => {
        // Replace spaces with underscores in the URL and title



        // Navigate to the desired path with the formatted URL and title
        navigate(`/coupon`, { state: item });
    };

    const getRowId = (row) => row._id
    return (
        <>
            {loading && <Loader />}
            {confirmDelete && (
                <ConfirmPopup

                    onConfirm={handleDeleteConfirmed}
                    onCancel={() => setConfirmDelete(false)}
                />
            )}
            <section>
                <div className="container">
                    <div className="grid grid-cols-1">
                        <div className="cols-span-1">
                            <TitleComp title={"Coupon List"} />
                        </div>
                        {hasPermission('Coupons', 'Write') && <div className="cols-span-1">
                            <div className="text-end mb-2">
                                <button className='btn bg-blue-500 px-5 py-2 rounded-md text-white' onClick={(e) => navigate(`/coupon`)}>Add Coupon</button>
                            </div>
                        </div>}
                        <div className="cols-span-1">
                            <TableComp data={data} columns={columns} getRowId={getRowId} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CouponList
