import React, { useEffect, useState } from 'react'
import { mastergetapi, superadmingetapi, superadminputapi } from '../../Api/Api'
import TableComp from '../../component/TableComp'
import Loader from '../../component/Loader';
import convertDateToMonthName from '../../component/FormDate';
import TitleComp from '../../component/TitleComp';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Checkbox } from '@mui/material';
import { toast } from 'react-toastify';
import { hasPermission } from '../../Access/Permission';

function User() {

    const [usertype, setusertype] = useState("");
    const [usertypedata, setUsertypedata] = useState([]);
    const [show, setshow] = useState(false)
    const [loading, setloading] = useState(false)
    const [data, setdata] = useState([])
    const token = localStorage.getItem("token")
    const [phonever, setphonever] = useState("")
    const [search, setsearch] = useState('')
    const [matchid, setmatchid] = useState("")
    const [isactive, setisactive] = useState("")
    const navigate = useNavigate()


    const { id } = useParams()

    const location = useLocation()




    const queryParams = new URLSearchParams(location.search);


    const fromDate = queryParams.get('from_date');
    const toDate = queryParams.get('to_date');


    useEffect(() => {
        if (id && usertypedata) {
            const result = usertypedata.filter((item) => item.name == id);


            if (result.length > 0 && result[0]?._id) {
                setusertype(result[0]._id); // Set the usertype to the matched id

            } else {

            }
        }
    }, [id, usertypedata]);


    const [reqeditbanksearch, setreqeditbanksearch] = useState("")
    const [reqeditdocsearch, setreqeditdocsearch] = useState("")


    const [filterdata, setfilterdata] = useState([])

    let filterusertype = localStorage.getItem("filterusertype")

    useEffect(() => {
        if (filterusertype) {
            setusertype(filterusertype)
        }
    }, [filterusertype])

    const handleusertype = async () => {
        setloading(true);
        let res = await mastergetapi('user_type', token);


        if (res.data.error) {
            toast.error(res.data.misc)
            setloading(false)
        } else {
            if (!res.error) {
                setloading(false);
                setUsertypedata(res.data);
                if (!filterusertype) {
                    localStorage.setItem("filterusertype", res.data[0]?._id)
                }
            }
        }



    };

    useEffect(() => {
        if (hasPermission('UserTypes', "Read")) {
            handleusertype()
        } else {
            toast.error("You need permission for User Types to view the details.");

        }
    }, [])

    const handleget = async () => {
        setloading(true)
        let res = ""
        if (queryParams.size != 0) {
            res = await superadmingetapi(`details?all=1&user_type=${usertype}&from_date=${fromDate?.substring(0, 10)}&to_date=${toDate.substring(0, 10)}`, token)
        } else {
            res = await superadmingetapi(`details?all=1&user_type=${usertype}`, token)
        }

        setdata(res.data)
        if (!res.error) {
            setloading(false)
        }
    }

    useEffect(() => {
        if (usertype) {
            handleget()
        }
    }, [usertype])

    const columns = [

        {
            field: "name",
            headerName: "Name",
            ...(matchid == "vendor" ? { width: 150 } : { flex: 1 }),
        },
        {
            field: "phone",
            headerName: "Phone",

            ...(matchid == "vendor" ? { width: 200 } : { flex: 1 }),
        },
        {
            field: "roles",
            headerName: "Roles",
            ...(matchid == "vendor" ? { width: 150 } : { flex: 1 }),

            renderCell: (params) => {
                return (
                    <span>{params.row.roles.length} Access</span>
                )
            }
        },
        {
            field: "is_phone_verified",
            headerName: "Phone Verified",
            ...(matchid == "vendor" ? { width: 130 } : { flex: 1 }),
            renderCell: (params) => {
                return (
                    <>

                        <button className='cutombtn' onClick={(e) => handlecheck(e, params.row._id, params.row.is_phone_verified, "is_phone_verified")} style={{ padding: "0" }}>
                            <Checkbox checked={params.row.is_phone_verified} />
                        </button>
                    </>
                )
            }
        },


        ...(hasPermission(`User`, 'Delete') ? [{
            field: "is_active",
            headerName: "Is Active",
            ...(matchid == "vendor" ? { width: 150 } : { flex: 1 }),
            renderCell: (params) => {
                return (
                    <>
                        <button className='cutombtn' onClick={(e) => handlecheck(e, params.row._id, params.row.is_active, "is_active")} style={{ padding: "0" }}>
                            <Checkbox checked={params.row.is_active} />
                        </button>
                    </>
                )
            }
        }] : []),
        // {
        //     field: "created_at",
        //     headerName: "Created Date",
        //     width:100,
        //     renderCell: (params) => { // Corrected renderCell casing
        //         return <span>{convertDateToMonthName(params.row.created_at)}</span>;
        //     },
        // },
        {
            field: "info",
            headerName: "Info",
            ...((matchid == "vendor" && hasPermission('User', 'Update')) ? { width: 100 } : { flex: 1 }),
            renderCell: (params) => { // Corrected renderCell casing
                return <button className='cutombtn' onClick={(e) => handleuserinfo(e, params.row)}><i className="fa-solid fa-circle-info"></i></button>;
            },
        },

        ...(hasPermission(`User`, 'Update') ? [{
            field: "edit",
            headerName: "Edit",
            ...(matchid == "vendor" ? { width: 100 } : { flex: 1 }),
            renderCell: (params) => { // Corrected renderCell casing
                return <button className='cutombtn' onClick={(e) => handleedit(e, params.row)}><i className="fa-solid fa-pen-to-square"></i></button>;
            },
        }] : []),
    ];










    if (matchid == "superadmin") {

        columns.push(
            {
                field: "Roles",
                headerName: "Roles",
                ...((matchid == "vendor" && hasPermission('User', 'Update')) ? { width: 100 } : { flex: 1 }),
                renderCell: (params) => {
                    return <button className='cutombtn' onClick={(e) => navigate(`/addpermission/vendor`, { state: params.row })}><i className="fa-solid fa-user-pen"></i></button>;
                },
            },

        );
    }
    if (matchid == "vendor") {

        columns.push(



            ...(hasPermission(`User`, 'Update') ? [{
                field: "can_edit_bank",
                headerName: "Request Bank Edit / Edit Bank ",
                ...(matchid == "vendor" ? { width: 230 } : { flex: 1 }),
                renderCell: (params) => {
                    return (
                        <>

                            <div className="flex gap-3 tabletooltip">
                                <div className="uiverse">
                                    {/* <span className="tooltip1">Edit Request </span> */}
                                    <button className='cutombtn  tooltipname' style={{ padding: "0" }} onClick={(e) => setshow(true)}><Checkbox checked={params.row.request_edit_bank} /></button>
                                </div>
                                <div className="uiverse">
                                    {/* <span className="tooltip1">Edit Bank </span> */}
                                    <button className='cutombtn ' style={{ padding: "0" }} onClick={(e) => setshow(true)}><Checkbox checked={params.row.can_edit_bank} /></button>
                                </div>
                            </div>
                        </>
                    );
                },
            }] : []),


            ...(hasPermission(`User`, 'Update') ? [{
                field: "can_edit_docs",
                headerName: "Edit Docuement /Request Document Edit",
                ...(matchid == "vendor" ? { width: 230 } : { flex: 1 }),
                renderCell: (params) => {
                    return (
                        <>
                            <div className="flex gap-3">
                                <button className='cutombtn ' style={{ padding: "0" }} onClick={(e) => setshow(true)}><Checkbox checked={params.row.request_edit_docs} /></button>
                                <button className='cutombtn ' style={{ padding: "0" }} onClick={(e) => setshow(true)}><Checkbox checked={params.row.can_edit_docs} /></button>

                            </div>
                        </>
                    )
                },
            }] : []),


            ...(hasPermission(`Stores`, 'Read') ? [{
                field: "store",
                headerName: "Stores",
                ...(matchid == "vendor" ? { width: 100 } : { flex: 1 }),
                renderCell: (params) => {
                    return <button className='cutombtn' onClick={(e) => navigate(`/user/stores/${params.row._id}`)}><i className="fa-solid fa-store"></i></button>;
                },
            }] : []),




            {
                field: "Roles",
                headerName: "Roles",
                ...(matchid == "vendor" ? { width: 100 } : { flex: 1 }),
                renderCell: (params) => {
                    return <button className='cutombtn' onClick={(e) => navigate(`/addpermission/vendor`, { state: params.row })}><i className="fa-solid fa-user-pen"></i></button>;
                },
            },

            ...(hasPermission(`Products`, 'Read') ? [{
                field: "product",
                headerName: "Product",
                ...(matchid == "vendor" ? { width: 100 } : { flex: 1 }),
                renderCell: (params) => {
                    return <button className='cutombtn' onClick={(e) => navigate(`/user/product/${params.row._id}`, { state: "user" })}><i className="fa-solid fa-boxes-stacked"></i></button>;
                },
            }] : []),

        );
    }





    useEffect(() => {

        const result = usertypedata?.filter((item) => item._id == usertype);

        // Check if there is at least one match
        if (result.length > 0 && result[0]?._id == usertype) {
            setmatchid(result[0]?.name)

        } else {

        }
    }, [usertypedata, usertype]);

    const handleedit = (e, item) => {
        e.preventDefault()
        navigate('/edituser', { state: item })
    }







    const handleuserinfo = (e, item) => {
        e.preventDefault()
        navigate("/userinfo", { state: item })
    }


    const handlecheck = async (e, id, verify, paramtitle) => {
        e.preventDefault();
        setloading(true);

        let requestdata = {
            [paramtitle]: !verify  // Use computed property name for dynamic key
        };

        let res = await superadminputapi(`update?_id=${id}`, requestdata, token);

        if (!res.error) {
            setloading(false);
            handleget();
        } else {
            setloading(false);  // Ensure loading is stopped even if there's an error
            // Handle the error, e.g., show a toast notification
        }
    };




    useEffect(() => {
        if (isactive != "") {
            const filtered = data.filter((item) => item.is_active.toString() == isactive);
            setfilterdata(filtered)
        } else {
            setfilterdata(data)
        }

    }, [isactive, data]);

    useEffect(() => {
        if (reqeditbanksearch != "") {
            const filtered = data.filter((item) => item.request_edit_bank.toString() == reqeditbanksearch);
            setfilterdata(filtered)
        } else {
            setfilterdata(data)
        }
    }, [reqeditbanksearch])

    useEffect(() => {
        if (reqeditdocsearch != "") {
            const filtered = data.filter((item) => item.request_edit_bank.toString() == reqeditdocsearch);
            setfilterdata(filtered)
        } else {
            setfilterdata(data)
        }
    }, [reqeditdocsearch])


    const getRowId = (row) => row._id


    const handlesearch = (e) => {
        e.preventDefault();

        const searchTerm = search?.toLowerCase();

        const filteredData = data.filter((item) =>
            item.name?.toLowerCase().includes(searchTerm) ||
            item.phone.includes(searchTerm)
        );

        setfilterdata(filteredData);
    };


    useEffect(() => {
        if (search == "") {
            setfilterdata(data)
        }
    }, [search])
    return (
        <>
            {loading && <Loader />}
            <section>
                <div className="container">
                    <div className="grid grid-cols-1">
                        {/* <TitleComp title="Users" /> */}
                        {hasPermission('User', 'Write') && <div className="col-span-1">
                            <div className="text-end my-3">
                                <button className='btn bg-blue-500 px-5 py-2 rounded-md text-white ' onClick={(e) => navigate('/adduser')}>Add User</button>
                            </div>
                        </div>}
                    </div>
                    <div className="grid grid-cols-6 gap-3 items-center">
                        <div className="col-span-1">
                            <form onSubmit={handlesearch}>
                                <div className="relative mt-4">
                                    <input type="text" className="w-full py-2 pl-10 pr-4 rounded border border-gray-300 focus:outline-none focus:border-blue-500" value={search} onChange={(e) => setsearch(e.target.value)} placeholder=" Name, Phone" />
                                    <i className="fa-solid fa-magnifying-glass absolute left-3 top-[15px] transform -translate-y-1/2 text-gray-500"></i>
                                </div>
                            </form>
                        </div>
                        {usertypedata.length > 0 && <div className="col-span-1 ">
                            <div className="">
                                <label htmlFor="type" className="block text-gray-700 text-sm font-medium mb-2">Filter According User Type</label>
                                <select name="type" id="type" className="block w-full mb-3 bg-gray-200 border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" value={usertype} onChange={(e) => { setusertype(e.target.value); localStorage.setItem("filterusertype", e.target.value) }}>
                                    <option value="" className='hidden' selected>Select User Type</option>
                                    {usertypedata?.map((item) => (
                                        <option key={item._id} value={item._id}>{item.name}</option>
                                    ))}
                                </select>
                            </div>

                        </div>}
                        {/* <div className="col-span-1 ">
                            <div className="">
                                <label htmlFor="type" className="block text-gray-700 text-sm font-medium mb-2">Filter According Phone Verified</label>
                                <select name="type" id="type" className="block w-full mb-3 bg-gray-200 border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" value={phonever} onChange={(e) => setphonever(e.target.value)}>
                                    <option value="">Select </option>
                                    <option value="true">True</option>
                                    <option value="false">False</option>

                                </select>
                            </div>

                        </div> */}
                        <div className="col-span-1 ">
                            <div className="">
                                <label htmlFor="type" className="block text-gray-700 text-sm font-medium mb-2">Filter According Is Active</label>
                                <select name="type" id="type" className="block w-full mb-3 bg-gray-200 border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" value={isactive} onChange={(e) => setisactive(e.target.value)}>
                                    <option value="">Select </option>
                                    <option value="true">Active</option>
                                    <option value="false">Inactive</option>

                                </select>
                            </div>

                        </div>

                        {(hasPermission('User', 'Update') && matchid == "vendor") &&
                            <>
                                <div className="col-span-1">
                                    <div className="">
                                        <label htmlFor="type" className="block text-gray-700 text-sm font-medium mb-2">Filter Request Edit Bank</label>
                                        <select name="type" id="type" className="block w-full mb-3 bg-gray-200 border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" value={reqeditbanksearch} onChange={(e) => setreqeditbanksearch(e.target.value)}>
                                            <option value="">Select </option>
                                            <option value="true">Active</option>
                                            <option value="false">Inactive</option>

                                        </select>
                                    </div>
                                </div>
                                <div className="col-span-1">
                                    <div className="">
                                        <label htmlFor="type" className="block text-gray-700 text-sm font-medium mb-2">Filter Request Edit Document</label>
                                        <select name="type" id="type" className="block w-full mb-3 bg-gray-200 border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" value={reqeditdocsearch} onChange={(e) => setreqeditdocsearch(e.target.value)}>
                                            <option value="">Select </option>
                                            <option value="true">Active</option>
                                            <option value="false">Inactive</option>
                                        </select>
                                    </div>
                                </div>
                            </>
                        }





                    </div>
                    <div className="grid grid-cols-1">

                        <TableComp data={filterdata ?? data} getRowId={getRowId} columns={columns} />
                    </div>
                </div>
            </section>
        </>
    )
}

export default User
