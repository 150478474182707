import React, { useEffect, useState } from 'react'
import TitleComp from '../component/TitleComp'
import { mastergetapi, masterpostapi } from '../Api/Api'
import { toast } from 'react-toastify'
import Loader from '../component/Loader'
import TableComp from '../component/TableComp'

function Setting() {
    const token = localStorage.getItem("token")
    const [key, setkey] = useState("")
    const [loading, setloading] = useState(false)
    const [value, setvalue] = useState("")

    const [data, setdata] = useState([])
    const handleget = async () => {
        let res = await mastergetapi(`settings`, token)

        setdata(res.data)
    }

    const getRowId = (row) => row._id

    const columns = [
        {
            field: "key",
            headerName: "Key",
            flex: 1,
        },
        {
            field: "value",
            headerName: "Value",
            flex: 1,

        },

        {
            field: "edit",
            headerName: "Edit",
            flex: 1,
            renderCell: (params) => { // Corrected renderCell casing
                return <button className='cutombtn' onClick={(e) => handleEdit(e, params.row)}><i className="fa-solid fa-pen-to-square"></i></button>;
            },
        },
        // {
        //     field: "delete",
        //     headerName: "Delete",
        //     flex: 1,
        //     renderCell: (params) => { // Corrected renderCell casing
        //         return <button className='cutombtn' onClick={(e) => showDeleteConfirmation(params.row)}><i className="fa-solid fa-trash"></i></button>;
        //     },
        // },

    ];


    const handleEdit = (e, item) => {
        e.preventDefault()
        setkey(item.key)
        setvalue(item.value)
    }
    useEffect(() => {
        handleget()
    }, [])
    const handlesubmit = async (e) => {
        e.preventDefault()
        let requestdata = {
            key: key,
            value: value
        }
        let res = await masterpostapi(`settings`, requestdata, token)

        if (!res.error) {

            setloading(false)
            toast.success("Data Submit Succesfully")
        } else {
            setloading(false)
        }
    }
    return (
        <>
            {loading && <Loader />}
            <section>
                <div className="container">
                    <div className="row">
                        <div className="">
                            <TitleComp title="Settings" />
                        </div>
                        <form onSubmit={handlesubmit}>
                            <div className="grid grid-cols-3 gap-10">
                                <div className="col-span-1">
                                    <label htmlFor="">Key</label>
                                    <input type="text" className="input w-full p-2 border border-gray-300 rounded-md shadow-sm transition-opacity duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        value={key} onChange={(e) => setkey(e.target.value)} />
                                </div>
                                <div className="col-span-1">
                                    <label htmlFor="">Value</label>
                                    <input type="text" className="input w-full p-2 border border-gray-300 rounded-md shadow-sm transition-opacity duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        value={value} onChange={(e) => setvalue(e.target.value)} />
                                </div>
                                <div className="col-span-1">
                                    <button className='btn bg-blue-500 text-white mt-6 px-8 rounded-md py-2'>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                        <div className="grid grid-cols-1 mt-5">
                            <TableComp columns={columns} data={data} getRowId={getRowId} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Setting
